<script setup lang="ts">
import { useShowStore } from '@/stores/show';

const showStore = useShowStore();

showStore.getCalendarsAllSvg();
</script>

<template>
  <div class="full-page">
    <div class="d-flex flex-wrap">
      <div v-for="c in showStore.calendarSvgs">
        {{ c.id }}
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div v-for="c in showStore.calendarSvgs">
        <svg class="cover" v-html="c.items[0].svg" @click="$router.push({ name: 'showSingle', params: { id: c.id } })"></svg>
        <label d-none>View {{ c.id }}</label>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.full-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #eee;
}

.cover {
  height: 400px;
  width: 400px;
  margin: 1rem;
  background-color: #fff;
}
</style>
