import { createApp, watch } from 'vue';
import { createPinia } from 'pinia';
import { useCalendarsStore } from './stores/calendars';
import { useFontsStore } from './stores/fonts';
import { useAuthStore } from './stores/auth';

import './assets/main.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

import * as FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css'

import App from './App.vue';
import router from './router';

console.log('Initializing Speedo');

const app = createApp(App);

app.use(FloatingVue);

const pinia = createPinia();
app.use(pinia);

const auth = useAuthStore();
auth.init();

const store = useCalendarsStore(); // Initialize state whether signed in or not
const fontsStore = useFontsStore(); 

watch(
  () => auth.account?.username,
  async (newValue,oldValue) => {
    // Not triggered by SignOut
    // console.log('watch auth.account', newValue, oldValue)
    store.getLocalStorage();
    await store.loadSharedData();
    await store.loadLightboxes(auth.account.username, true);
    await store.loadCalendars(true);
    await fontsStore.loadGoogleFonts();
  },
  { deep: true }
);

app.use(router);

app.mount('#app');

console.log('App Mounted');