<script lang="ts" setup>
import { ref, watch } from 'vue';
import { nextTick } from 'process';

interface Props {
  text: string;
  inputType?: string;
  id?: string; // optional reference that is passed back on close
  property?: string; // optional reference that is passed back on close
}
const props = defineProps<Props>();
const emits = defineEmits(['close']);

const edit = ref(false);
const inputControl = ref(null);
const newText = ref(props.text);
const type = props.inputType || 'text';

watch(
  () => props.text,
  (newValue) => {
    newText.value = newValue;
  }
);

async function activateEdit(e: any) {
  edit.value = true;
  await nextTick(() => inputControl.value.focus());
}

function saveKeyUp(e: any) {
  if (e.key === 'Enter') save();
}

function save() {
  edit.value = false;
  emits('close', newText.value, props.id, props.property);
}
</script>

<template>
  <div class="text-edit">
    <input ref="inputControl" :type="type" min="6" max="72" v-model="newText" @blur="save" @keyup="saveKeyUp" class="form-control form-control-sm input-control" :class="{ 'hide-input': !edit }" />
    <div v-if="props.text" class="editable" :class="{ 'hide-input': edit }" v-tooltip="'Click to edit'" @click="activateEdit">{{ props.text }}</div>
    <div v-else @click="activateEdit" class="editable text-muted" :class="{ 'hide-input': edit }">Edit</div>
  </div>
</template>

<style lang="scss" scoped>

.hide-input {
  display: none;
}

.editable {
  &.text-muted {
    opacity: 0.4;
  }

  background-color: #fff; //#f5d9b0;
  border: 1px solid rgb(199, 186, 163);
  padding: 0.125rem 0.5rem;
  border-radius: 0.125rem;

  &:hover {
    border-color: rgb(226, 124, 0);
  }
}
</style>
