<script setup lang="ts">
import { ref } from 'vue';
import { useFontsStore } from '@/stores/fonts';
import { useModalStore } from '@/stores/modal';
import type { ICalendar, INameValue } from '@/types/image';

import ModalConfirm from '@/components/ModalConfirm.vue';
import TextEdit from '@/components/TextEdit.vue';
import SelectEdit from '@/components/SelectEdit.vue';

const fontsStore = useFontsStore();
const modalStore = useModalStore();

fontsStore.loadFonts();

const phrases = [
  '’Twas brillig, and the slithy toves  Did gyre and gimble in the wabe: All mimsy were the borogoves, And the mome raths outgrabe.',
  'The quick brown fox jumps over the lazy dog.',
  'Waltz, bad nymph, for quick jigs vex.',
  'Pack my box with five dozen liquor jugs.',
  'Five quacking zephyrs jolt my wax bed.',
  'The wizard quickly jinxed the gnomes before they vaporized.',
  'When zombies arrive, quickly fax judge Pat.',
  'Vintage images of the Missions Basilica San Diego de Alcala, San Luis Rey, and San Juan Capistrano, circa  1920-1930',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ abcdefghijklmnopqrstuvwxyz 01234567890 $%&(),.',
];
const phrase = ref(phrases[7]);

const editFont = ref(0);
const fontSize = ref(16);
const searchText = ref('');
const calendarsUsingFont = ref([] as ICalendar[]);

// <style>
//   @import url('https://fonts.googleapis.com/css2?family=Agbalumo&family=Cabin+Condensed&family=Cabin:ital,wght@1,400;1,500&display=swap');
// </style>

function insertGoogleFont(fontName: string) {
var newStyle = document.createElement('style');
      newStyle.appendChild(document.createTextNode("\
      @import url('https://fonts.googleapis.com/css2?family=" + fontName + "&display=swap');"));
      document.head.appendChild(newStyle);
}

function toggleEdit(fontId: number) {
  editFont.value = fontId;
}

function truncate(text): any {
  if (text.length > 45) text = text.slice(0, 45) + '...';
  return text;
}

function textEditFontClose(content, id, property) {
  const font = fontsStore.fonts.find((f) => f.id == id);
  font[property] = content;
  fontsStore.saveFont(font);
}

function confirmDeleteFont(fontId: number) {
  modalStore.open('modalDeleteFont', { fontId: fontId });
}

function deleteFontClose(confirm: boolean) {
  if (confirm) {
    fontsStore.deleteFont(modalStore.data.fontId)
    .then((calendars) => {
      if(calendars){
        console.log(calendars[0])
        calendarsUsingFont.value = calendars;
        modalStore.open('modalDeleteFontError');
      }
  });
  }
}

function fontInUseMessage(){
  if(calendarsUsingFont.value.length == 0){
    return "An unknown error occurred while deleting this font."
  }
  const limit = 5;
  let message = calendarsUsingFont.value
  .slice(0,limit)
  .reduce((a,c)=> a + '<br/>' + c.year + ' ' + c.title + ' <small>(' + c.identityName + ')</small>', '<strong>This font can\'t be deleted because it is in use by</strong>')
  if(calendarsUsingFont.value.length > limit){
    message += '<br/><strong>and ' + (calendarsUsingFont.value.length - limit) + ' more.</strong>'
  }
  return  message;
}

async function newFont() {
  fontsStore.newFont().then(function (fontId) {
    editFont.value = fontId;
  });
}

function googleFontSelected(fontFamily: string){
  const googleFont = fontsStore.googleFontsSelect.find((gFont)=> gFont.name == (fontFamily || 'Roboto'));
  return googleFont;
}

function googleFontVariantSelected(fontId: number){
  const font = fontsStore.fonts.find((f) => f.id == fontId);
  const googleFont = fontsStore.googleFontsSelect.find((gFont)=> gFont.name == (font.fontFamily || 'Roboto'));
  const variant = googleFont.options.find((f)=>f.name == font.fontWeight)
  return variant?.value || 0;
}

function selectGoogleFontClose(selectedOption, id) {
  const font = fontsStore.fonts.find((f) => f.id == id);
  const fontFamily = fontsStore.googleFontsSelect.find((gFont) => gFont.value == selectedOption.value)
  font.fontFamily = fontFamily.name;
  font.fontWeight = 'regular';
  fontsStore.saveFont(font);

  insertGoogleFont(fontFamily.name);
}

function selectGoogleFontVariantClose(selectedOption, id){
  const font = fontsStore.fonts.find((f) => f.id == id);
  const googleFont = fontsStore.googleFontsSelect.find((gFont)=> gFont.name == (font.fontFamily || 'Roboto'));
  const variant = googleFont.options.find((o)=>o.value == selectedOption.value);
  font.fontWeight = variant.name;
  console.log(variant)
  fontsStore.saveFont(font);
}
</script>

<template>
  <div class="container">
    <div class="row mt-3">
      <div class="col-12 col-3">
        <h3>Fonts & Styles</h3>
      </div>
      <div class="col-12 d-flex justify-content-end align-items-center">
        
        <button @click="newFont()" class="btn btn-outline-secondary btn-sm me-auto">NEW FONT</button>        
        <div>
          PREVIEW <strong class="ms-3">{{ fontSize }}pt</strong>
        </div>
        <input style="width: 200px" v-model="fontSize" type="range" class="form-range ms-3" min="6" max="38" step="2" id="customRange3" />
        <select v-model="phrase" style="width: 260px" class="form-select form-select-sm ms-3">
          <option style="width: 200px" v-for="c in phrases" :value="c">{{ truncate(c) }}</option>
        </select>

        <div class="input-group input-group-sm ms-3" style="width:200px;">
          <span class="input-group-text" id="basic-addon1"><i class="bi-search"></i></span>
          <input type="text" v-model="searchText" class="form-control form-control-sm" placeholder="Font Name" aria-label="Search" aria-describedby="basic-addon1" />
          <button @click="searchText = ''" class="btn btn-secondary" id="basic-addon1"><i class="bi-x"></i></button>
        </div>
       
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th class="align-top" style="width: 2%"></th>
              <th class="align-top" style="width: 20%"></th>
              <th class="align-top" style="width: 20%"></th>
              <th class="align-top" style="width: 8%"></th>
              <th class="align-top" style="width: 60%"></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="font in fontsStore.fonts.filter(f=>f.name.toLowerCase().includes(searchText.toLowerCase()))" :key="font.id">
              <tr v-if="editFont == font.id" @click="toggleEdit(font.id)" class="font-header-edit">
                <td>
                  <label>ID</label>
                  <input :value="font.id" disabled style="width: 2rem" />
                </td>
                <td>
                  <label>NAME</label>
                  <TextEdit :text="font.name" :id="font.id.toString()" :property="'name'" @close="textEditFontClose"></TextEdit>
                </td>
                <td>
                  <label>GOOGLE FONT <a :href="'https://fonts.google.com/specimen/' + font.fontFamily.replace(' ','+')" target="_blank"><i class="bi bi-box-arrow-up-right"></i></a></label>
                  <SelectEdit :name-values="fontsStore.googleFontsSelect" :id="font.id.toString()" :selected-value="googleFontSelected(font.fontFamily).value" :always-editable="true" @close="selectGoogleFontClose"></SelectEdit>
                </td>
                <td>
                  <label>VARIANT</label>
                  <SelectEdit :name-values="googleFontSelected(font.fontFamily).options" :id="font.id.toString()" :selected-value="googleFontVariantSelected(font.id)" :always-editable="true" @close="selectGoogleFontVariantClose"></SelectEdit>
               </td>
                <td>
                  <label>OTHER STYLES</label>
                  <TextEdit :text="font.fontOther" :id="font.id.toString()" :property="'fontOther'" @close="textEditFontClose"></TextEdit>
                </td>
                <td class="text-end">
                  <button @click="confirmDeleteFont(font.id)" class="btn btn-sm btn-outline-secondary"><i class="bi-trash"></i></button>
                </td>
              </tr>
              <tr v-else class="font-header" @click="toggleEdit(font.id)">
                <td colspan="6">{{ font.name }} {{ font.fontWeight ? '(' + font.fontWeight + ')' : '' }}</td>
              </tr>
              <tr class="font-sample" @click="toggleEdit(font.id)">
                <td colspan="6" :class="{ selected: editFont == font.id }">
                  <div :style="'font-size:' + fontSize + 'pt;font-family:' + font.fontFamily + ';font-weight:' + font.fontWeight + ';' + font.fontOther">{{ phrase }}</div>
                </td>
              </tr>
              <tr class="spacer">
                <td colspan="6"></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <div class="column"></div>
    </div>
  </div>

  <ModalConfirm id="modalDeleteFont" @close="deleteFontClose" :message="`Are you sure you want to permenantly delete this font?`"></ModalConfirm>
  <ModalConfirm id="modalDeleteFontError" ref="modalDeleteFontError" :message="fontInUseMessage()" :title="'Error'" :ok-button="null" :close-button="{ label: 'Close', color: 'primary' }"></ModalConfirm>

</template>

<style lang="scss" scoped>
$selected-border: 3px solid #ddd;

select,
option {
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table {
  font-family: 'Cabin Condensed';

  td {
    cursor: pointer;
  }
}

tr.font-header {
  td {
    font-weight: 600;
    font-size: 1rem;
    background-color: #f5f5f5;
    padding-top: 0;
    padding-bottom: 0;
  }
}
tr.font-header-edit {
  td {
    height: 48px;
    padding-top: 0;
    font-size: 1rem;
    background-color: #f5f5f5;
    border-top: $selected-border;

    &:first-child {
      border-left: $selected-border;
    }

    &:last-child {
      border-right: $selected-border;
    }

    label {
      font-size: 0.7rem;
      vertical-align: bottom;
    }

    button {
      margin-top: 1.6rem;
    }
  }
}
tr.font-sample td {
  background-color: #fff;

  div {
    padding: 1rem;
  }

  &.selected {
    padding: 0;
    border-left: $selected-border;
    border-right: $selected-border;
    border-bottom: $selected-border;
  }
}

tr.spacer td {
  padding: 0;
  height: 1px;
  background-color: #ddd;
}
</style>
