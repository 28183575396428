import { defineStore } from 'pinia';
import axios from 'axios';
import type { ICalendar, IFont, IGoogleFont, INameValue, IStyle } from '@/types/image';

const STATES = {
  INIT: 0,
  DONE: 1,
  WIP: 2,
  ERROR: 3,
};

const api = 'https://bikini-web-calendars.azurewebsites.net';
// const api = 'https://localhost:7241';

const GOOGLE_API_KEY = 'AIzaSyCLqhO1iSNF2R_1RG2GsNJHMKuIp9ftf2U';
const GOOGLE_API = 'https://www.googleapis.com/webfonts/v1/webfonts?key=' + GOOGLE_API_KEY;

export const useFontsStore = defineStore('fonts', {
  state: () => ({
    fonts: [] as IFont[],
    dataState: STATES.INIT,
    googleFonts: [] as IGoogleFont[],
    googleFontsSelect: [] as INameValue[],
  }),
  actions: {
    loadFonts() {
      this.dataState = STATES.WIP;
      axios
        .get(api + '/api/font')
        .then((res) => {
          this.fonts = res.data;
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    saveFont(font: IFont) {
      this.dataState = STATES.WIP;
      axios
        .put(api + '/api/font/' + font.id, font)
        .then((res) => {
          this.dataState = STATES.DONE;
          // this.loadFonts();
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    deleteFont(fontId: number) {
      this.dataState = STATES.WIP;
      return axios
        .delete(api + '/api/font/' + fontId)
        .then((res) => {
          const calendars = res.data as ICalendar[];
          if(calendars.length > 0){
            this.dataState = STATES.DONE;
            return calendars;
          }
          this.loadFonts();
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    newFont() {
      const newFont = {
        id: 0,
        name: 'New Font Style',
        fontFamily: 'Roboto',
        fontWeight: 'regular',
        fontOther: '',
      } as IFont;

      this.dataState = STATES.WIP;
      return axios
        .post(api + '/api/font', newFont)
        .then((res) => {
          const font = res.data;
          this.fonts.unshift(font);
          this.dataState = STATES.DONE;
          return font.id;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    async loadGoogleFonts() {
      this.dataState = STATES.WIP;
      return axios
        .get(GOOGLE_API)
        .then((res) => {
          this.googleFonts = res.data.items;
          this.dataState = STATES.DONE;
          this.googleFontsSelect = this.googleFonts.map(
            (gFont, i) =>
              ({
                name: gFont.family,
                value: i,
                options: gFont.variants
                .filter((variant)=> !variant.endsWith('italic') )
                .map((variant, i) => ({
                  name: variant,
                  value: i,
                } as INameValue)),
              } as INameValue)
          );
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
  },
});
