<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
const auth = useAuthStore();
</script>

<template>
  <div class="home-splash">
    <h3>Bikini Systems Prototypes</h3>

    <div v-if="auth.account">
      <p>Welcome {{ auth.account?.name }}</p>
      <button class="btn btn-outline-secondary m-1" @click="auth.SignOut">Sign Out</button>
    </div>
    <div v-else>
      <button class="btn btn-outline-secondary m-1" @click="auth.SignIn">Sign In</button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-splash {
  padding: 2rem;
}
</style>
