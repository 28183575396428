<script setup lang="ts">
import { ref } from 'vue';
import { useCalendarsStore } from '@/stores/calendars';
import { useTableStore } from '@/stores/table';
import SortButton from '@/components/SortButton.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';

const store = useCalendarsStore();
const table = useTableStore();
const auth = useAuthStore();

let deleteCalendarId = 0;

async function createCalendar() {
  await store.createCalendarForUser();
  router.push({ name: 'calendar', params: { id: store.calendar.id } });
}

function modalConfirmClose(confirm: boolean) {
  if (confirm) {
    store.deleteCalendar(deleteCalendarId);
  }
}

function calendarsFiltered() {
  return store.calendars.filter(
    (c) =>
      c.title.toLowerCase().includes(table.searchText.toLowerCase()) &&
      (table.searchYear == 0 || c.year == table.searchYear) &&
      (table.searchShared || c.identityName == auth.account.username) &&
      (table.searchVendorId == 0 || c.vendorId == table.searchVendorId) &&
      (table.searchStatus == 0 || c.status == table.searchStatus)
  );
}
</script>

<template>
  <div class="container">
    <div class="calendars row mt-3 mb-3">
      <div class="col-12">
        <h3>Calendars</h3>
      </div>
      <div class="col-12 d-flex justify-content-end align-items-center">
        <button @click="createCalendar" class="btn btn-sm btn-outline-secondary me-auto">NEW CALENDAR</button>
        <div class="form-check form-switch ms-3">
          <input v-model="table.searchShared" class="form-check-input" type="checkbox" role="switch" id="lightboxesShowShared" />
          <label class="form-check-label" for="lightboxesShowShared">Include Shared</label>
        </div>
        <select v-model="table.searchVendorId" class="form-select form-select-sm ms-3" style="width:200px;">
          <option value="0">All Vendors</option>
          <option v-for="vendor in store.vendors" :value="vendor.value">{{ vendor.name }}</option>
        </select>
        <select v-model="table.searchYear" class="form-select form-select-sm ms-3" style="width:100px;">
          <option value="0">All Years</option>
          <option v-for="year in store.calendarYears.years" :value="year.value">{{ year.name }}</option>
        </select>
        <select v-model="table.searchStatus" class="form-select form-select-sm ms-3" style="width:200px;">
          <option value="0">All Statuses</option>
          <option v-for="status in store.statuses" :value="status.value">{{ status.name }}</option>
        </select>
        <div class="input-group input-group-sm ms-3" style="width: 200px;">
          <span class="input-group-text" id="basic-addon1"><i class="bi-search"></i></span>
          <input type="text" v-model="table.searchText" class="form-control form-control-sm" placeholder="Title" aria-label="Search" aria-describedby="basic-addon1" />
          <button @click="table.searchText = ''" class="btn btn-secondary" id="basic-addon1"><i class="bi-x"></i></button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-light table-hover">
          <thead>
            <tr>
              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Year"
                  property="year"
                ></sort-button>
              </th>
              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Title"
                  property="title"
                ></sort-button>
              </th>
              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Vendor"
                  property="vendorName"
                ></sort-button>
              </th>
              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Owner"
                  property="identityName"
                ></sort-button>
              </th>
              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Status"
                  property="status"
                ></sort-button>
              </th>
              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Permission"
                  property="permission"
                ></sort-button>
              </th>
              <th class="text-end" style="width: 1%">
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Product"
                  property="productId"
                ></sort-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="c in calendarsFiltered()" :key="c.id" :value="c.id">
              <tr class="static-row" @click="$router.push({ name: 'calendar', params: { id: c.id } })">
                <td>{{ c.year }}</td>
                <td>{{ c.title }}</td>
                <td>{{ c.vendorName }}</td>
                <td>
                  <small>{{ c.identityName }}</small>
                </td>
                <td>{{ c.statusName }}</td>
                <td>{{ c.permissionName }}</td>
                <td class="text-end">
                  <a
                    v-if="c.productId"
                    :href="'https://integration.foundimagepress.com/Products/Edit/' + c.productId"
                    class="btn btn-sm btn-outline-secondary"
                    style="width: 80px"
                    target="_blank"
                    >{{ c.productId }}</a
                  >
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <modal-confirm id="confirmDeleteCalendar" @close="modalConfirmClose" :message="'Are you sure you want to permenantly delete this calendar?'"></modal-confirm>
  </div>
</template>

<style lang="scss" scoped>
.table tr td {
  cursor: pointer;
}

.cancel-all {
  display: none;

  &.show {
    display: inline-block;
  }
}

.edit-controls {
  min-width: 130px;
}

.static-row {
  .edit-controls {
    button {
      opacity: 0;
    }
  }

  &:hover {
    .edit-controls {
      button {
        opacity: 1;
      }
    }
  }
}

.add-row td {
  opacity: 1;

  .active {
    display: block;
  }
  .inactive {
    display: none;
  }

  &:hover {
    background-color: #fff;
    opacity: 1;
    cursor: pointer;

    .active {
      display: none;
    }
    .inactive {
      display: block;
    }
  }
}
</style>
