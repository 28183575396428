<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';
const store = useCalendarsStore();
</script>

<template>
  <div class="search-mode me-2">
    <div class="btn-group btn-group-sm" role="group" aria-label="Set search mode">
      <input id="basic" class="btn-check" type="radio" name="searchMode" value="basic" autocomplete="off" v-model="store.searchMode" />
      <label class="btn btn-outline-secondary" for="basic" v-tooltip="'Basic Mode'"><i class="bi-star"></i></label>
      <input id="pro" class="btn-check" type="radio" name="searchMode" value="pro" autocomplete="off" v-model="store.searchMode" />
      <label class="btn btn-outline-secondary" for="pro" v-tooltip="'Pro Mode'"><i class="bi-star-half"></i></label>
      <input id="expert" class="btn-check" type="radio" name="searchMode" value="expert" autocomplete="off" v-model="store.searchMode" />
      <label class="btn btn-outline-secondary" for="expert" v-tooltip="'Expert Mode'"><i class="bi-star-fill"></i></label>
      <input id="artCodes" class="btn-check" type = "radio" name="searchMode" value="artCodes" autocomplete="off" v-model="store.searchMode">
      <label class="btn btn-outline-secondary" for="artCodes" v-tooltip="'Art Codes Mode'"><i class="bi-hash"></i></label>
      <button class="btn btn-light btn-sm" @click="store.toggleSearchControl('helpOpen')" v-tooltip="'Search Help'">
          <i class="bi-question-lg"></i>
        </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-mode {
  z-index: 3;
}
.btn-light {
  border: 1px solid #666;
}
</style>