<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';
const store = useCalendarsStore();

interface Props {
  showMode: string; // active, inactive, demo-any, demo-all
}

const props = defineProps<Props>();
</script>

<template>
  <div class="any-all me-auto" v-show="props.showMode == 'active'">
    <div class="btn-group btn-group-sm" role="group" aria-label="Any or all toggle">
      <input id="any" class="btn-check" type="radio" name="anyAll" value="any" autocomplete="off" v-model="store.anyAll" />
      <label class="btn btn-outline-secondary" for="any" v-tooltip="'Match any term'"><i class="bi-asterisk"></i></label>
      <input id="all" class="btn-check" type="radio" name="anyAll" value="all" autocomplete="off" v-model="store.anyAll" />
      <label class="btn btn-outline-secondary" for="all" v-tooltip="'Match all terms'">&sum;</label>
    </div>
  </div>
  <div class="any-all me-auto faded" v-show="props.showMode == 'inactive'">
    <div class="btn-group btn-group-sm">
      <input class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary"><i class="bi-asterisk"></i></label>
      <input id="all" class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary">&sum;</label>
    </div>
  </div>
  <div class="any-all demo" v-show="props.showMode == 'demo-any'">
    <div class="btn-group btn-group-sm">
      <input class="btn-check" type="radio" checked />
      <label class="btn btn-outline-secondary"><i class="bi-asterisk"></i></label>
      <input id="all" class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary">&sum;</label>
    </div>
  </div>
  <div class="any-all demo" v-show="props.showMode == 'demo-all'">
    <div class="btn-group btn-group-sm">
      <input class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary"><i class="bi-asterisk"></i></label>
      <input id="all" class="btn-check" type="radio" checked />
      <label class="btn btn-outline-secondary">&sum;</label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn {
  font-family: 'Cabin Condensed';
}

.faded {
  opacity: 0.3;
  pointer-events: none;
}

.demo .btn {
  font-size: 0.5rem;
  padding: 0.125rem 0.25rem;
}
</style>