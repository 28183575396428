<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';
import { useModalStore } from '@/stores/modal';
import Query from './Query.vue';

const store = useCalendarsStore();
const modal = useModalStore();

function toggleCategories() {
  if (!store.toggleSearchControl('categoriesOpen')) {
    store.searchNow();
  }
}
</script>

<template>
  <div class="above-backdrop">

    <div class="search-input">
      <div class="input-group input-group-sm">
        <span class="input-group-text"><i class="bi-search"></i></span>
        <input id="searchText" v-model="store.searchText" class="form-control form-control-sm" :class="{ invalid: !store.searchIsValid }" />
        <button class="btn btn-light" @click="store.searchText = ''" v-tooltip="'Clear Search Text'"><i class="bi-eraser-fill"></i></button>
        <button class="btn btn-sm" :class="[store.search.isValid ? 'btn-success' : 'btn-warning']" @click="store.toggleSearchControl('queryOpen')" v-tooltip="'Show Query Code'">
          <i class="bi-braces"></i>
        </button>

        <query></query>

      </div>

      <div class="selected-categories ms-3" :class="{ open: store.searchControls.categoriesOpen }" @click="toggleCategories">
        <span v-if="store.selectedCategories().length == 0" class="ms-1">Select categories</span>
        <template v-else>
          <span class="category-code" v-for="category in store.selectedCategories()" v-tooltip="category.name">{{ category.code }}</span>
          <span class="ms-3 clear-categories" @click="store.clearCategories" v-tooltip="'Clear Categories'"><i class="bi-eraser-fill"></i></span>
        </template>
        <span class="ms-auto"><i class="bi-caret-right-fill caret" :class="{ pivot: modal.isOpen['modalCategories'] }"></i></span>
      </div>
      <button @click="store.clearSearch" class="btn btn-sm btn-outline-secondary ms-3" v-tooltip="'Clear'">
        <i class="bi-eraser-fill"></i>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.above-backdrop {
  z-index: 3;
}
.search-input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  #searchText {
    &.invalid {
      color: red;
    }
  }

  .btn {
    font-family: 'Cabin Condensed';
  }

  .btn-light {
    border: 1px solid #ccc; // input-group requires border
    color: #999;
  }

  .faded {
    opacity: 0.5;
    pointer-events: none;
  }

  .input-group {
    width: unset;
    min-width: 320px;
  }

  .selected-categories {
    min-width: 180px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    background-color: #eee;
    padding: 0.25rem;
    z-index: 2;

    &.open {
      border: 1px solid #999;
      border-bottom: 1px solid #ddd;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .category-code {
      font-family: 'Cabin Condensed';
      font-size: 0.8rem;
      text-align: center;
      display: block;
      width: 22px;
      height: 22px;
      margin-right: 0.125rem;
      background-color: #666;
      border-radius: 0.125rem;
      color: #fff;
      padding-top: 1px;
      cursor: help;
    }

    .clear-categories {
      color: #999;
      &:hover {
        cursor: pointer;
        color: #333;
      }
    }

    .caret:before {
      font-size: 1rem;
      color: #666;
      transform: rotate(0);
      transition: transform 500ms;
    }
    .caret.pivot:before {
      transform: rotate(90deg);
      transition: transform 500ms;
    }
  }
}
</style>
