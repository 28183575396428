<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';
import { ICategory } from '@/types/image';

interface Props {
  title: string;
  classificationId: number; // -1 == all
}

const props = defineProps<Props>();
const store = useCalendarsStore();
const columnLenth = props.classificationId == -1 ? 39 : 45;
const pages = Math.ceil(store.categories.filter((c) => props.classificationId == -1 || c.classificationId == props.classificationId).length / columnLenth);

function namePart1(name: string) {
  var i = name.toLowerCase().indexOf(store.categoryFilter.toLowerCase());
  return name.slice(0, i);
}
function namePart2(name: string) {
  var i = name.toLowerCase().indexOf(store.categoryFilter.toLowerCase());
  return name.slice(i, i + store.categoryFilter.length);
}
function namePart3(name: string) {
  var i = name.toLowerCase().indexOf(store.categoryFilter.toLowerCase()) + store.categoryFilter.length;
  return name.slice(i);
}
function isCodeMatched(code: string) {
  return store.categoryFilter.toLowerCase() == code.toLowerCase();
}
function isNameMatched(categoryName: string) {
  return store.categoryFilter != '' && categoryNameIncludes(categoryName);
}
function isNotMatched(category: ICategory) {
  return store.categoryFilter != '' && !(categoryNameIncludes(category.name) || isCodeMatched(category.code));
}
function categoryNameIncludes(categoryName: string) {
  return categoryName.toLowerCase().includes(store.categoryFilter.toLowerCase());
}
</script>

<template>
  <div class="classification">
    <p>{{ title }}</p>
    <div class="columns">
      <div v-for="page in pages" class="column">
        <div
          class="form-check"
          v-for="category in store.categories
            .filter((c) => c.vendorId == store.categoryVendorId)
            .filter((c) => props.classificationId == -1 || c.classificationId == props.classificationId)
            .slice((page - 1) * columnLenth, page * columnLenth)"
          :key="category.categoryId"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :id="'id' + category.categoryId"
            v-model="category.isSelected"
          />
          <label
            class="form-label"
            :class="{ 'not-matched': isNotMatched(category) }"
            :for="'id' + category.categoryId"
          >
            <span>
              <span :class="{ 'code-match': isCodeMatched(category.code) }">{{ category.code }}</span>
            </span>

            <span v-if="isNameMatched(category.name)">
              <span>{{ namePart1(category.name) }}</span>
              <strong>{{ namePart2(category.name) }}</strong>
              <span>{{ namePart3(category.name) }}</span>
            </span>
            <span v-else>{{ category.name }} <i v-show="category.classificationId==0" class="bi-exclamation-diamond text-danger"></i></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.classification {
  flex-grow: 1;
  margin-left: 0.35rem;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 0.25rem;

  &:first-of-type {
    margin-left: 0;
  }
}
.columns {
  display: flex;
}
.column {
  flex-grow: 1;
  margin: 0 0.25rem;
}

h5 {
  min-height: 50px;
}
.form-label {
  display: flex;
  font-family: 'Cabin Condensed';
  font-size: 0.8rem;
  margin: 0;

  span:first-child {
    width: 1.4rem;
    min-width: 1.4rem;
  }

  &.not-matched {
    opacity: 0.4;
  }

  strong {
    font-weight: normal;
    background-color: rgb(248, 228, 203);
    color: rgb(219, 122, 3);
  }
  .code-match {
    background-color: rgb(248, 228, 203);
    color: rgb(219, 122, 3);
  }
}

.form-check {
  margin: 0;
}
</style>
