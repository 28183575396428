<script setup lang="ts">
import { ref } from 'vue';
import { useCalendarsStore } from '@/stores/calendars';
import Classification from './Classification.vue';
import CategoriesSortToggle from './CategoriesSortToggle.vue';
import CategoriesLayoutToggle from './CategoriesLayoutToggle.vue';

const store = useCalendarsStore();

const parentLayout = ref('classifications');

function sortChanged(sortProperty: string) {
  store.categorySortProperty = sortProperty;
  store.sortCategories();
}

function layoutChanged(layout: string)
{
  parentLayout.value = layout;
}

function keyUp(e:any){
  console.log(e.key);
  if(e.key == 'Enter'){
    store.searchControls.categoriesOpen = false;
  }
}
</script>

<template>
  <div v-show="store.searchControls.categoriesOpen" class="modal-backdrop" @click="store.closeSearchControls"></div>
  <div v-show="store.searchControls.categoriesOpen" class="modal-categories-header" @keyup="keyUp">
    <div>Categories</div>
    <div class="ms-auto me-3">
      <select v-model="store.categoryVendorId" class="form-select form-select-sm">
        <option value="1">Found Image Press</option>
        <option value="2">Rigel Paper</option>
      </select>
    </div>
    <div class="me-3">
      <categories-sort-toggle @sort-changed="sortChanged"></categories-sort-toggle>
    </div>
    <div>
      <categories-layout-toggle @layout-changed="layoutChanged"></categories-layout-toggle>
    </div>
    <div class="input-group input-group-sm ms-3">
      <span class="input-group-text"><i class="bi-search"></i></span>
      <input class="form-control form-control-sm" v-model="store.categoryFilter" />
      <span class="input-group-text" @click="store.categoryFilter = ''"><i class="bi-x"></i></span>
    </div>
  </div>
  <div v-show="store.searchControls.categoriesOpen" class="modal-categories" @keyup = "keyUp">
    <div v-if="parentLayout == 'classifications'" class="categories">
      <Classification :classificationId="0" title="Private"></Classification>
      <Classification :classificationId="1" title="Regional"></Classification>
      <Classification :classificationId="2" title="Topical"></Classification>
      <Classification :classificationId="3" title="Seasonal & Occasional"></Classification>
    </div>
    <div v-else class="categories">
      <Classification :classificationId="-1" title="All Classifications"></Classification>
    </div>    
  </div>
</template>

<style lang="scss" scoped>
.modal-backdrop {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-categories-header {
  position: absolute;
  left: 48px;
  right: 48px;
  top: 42px;
  height: 51px;
  z-index: 2;
  background-color: #f0f0f0;
  border: 1px solid #999;

  display: flex;
  justify-items: space-between;
  align-items: center;
  padding: 0.5rem;

  .input-group {
    max-width: 200px;
  }
}

.modal-categories {
  position: absolute;
  left: 48px;
  right: 48px;
  top: 92px;
  bottom: 30px;
  z-index: 2;
  background-color: #f0f0f0;
  border: 1px solid #999;
  overflow-y: scroll;
  padding: 0.35rem;
  border-radius: 0.25rem;
  border-top-right-radius: 0;

  .categories {
    display: flex;
    flex-direction: row;
  }
}
</style>
