<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';

const store = useCalendarsStore();
</script>

<style scoped lang="scss">

.query {
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 0;
  min-width: 320px;
  min-height: 115px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 0.8rem;

  .messsage {
    font-weight: bold;
  }
}
</style>

<template>

  <div class="query" v-show="store.searchControls.queryOpen">
    <input :value="store.search.searchQuery" placeholder="No Query" class="form-control form-control-sm mb-1" readonly />
    <input :value="store.search.searchFilter" placeholder="No Filter" class="form-control form-control-sm mb1" readonly />
    <div class="message mt-2">
      <span class="text-success" v-if="store.search.isValid">Valid <i class="bi-check-lg"></i></span>
      <span class="text-danger" v-else>{{ store.search.message }}</span>
    </div>
  </div>
</template>
