<script setup lang="ts">
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCalendarsStore } from '@/stores/calendars';
import { useToggleStore } from '@/stores/toggle';
import TextEdit from '@/components/TextEdit.vue';
import SelectEdit from '@/components/SelectEdit.vue';
import { ILayoutItem } from '@/types/image';

const store = useCalendarsStore();
const toggleStore = useToggleStore();
const route = useRoute();
const id = route.params.id;
const calendarId = Number.parseInt(typeof id == 'string' ? id : id[0]);
const svgCover = ref('');
const svgBack = ref('');
const svgMonths = ref([]);
const pdfUrl = ref('');
const pdfPath = ref('');

store.loadCalendarWithImageDuplicates(calendarId).then(() => console.log(store.calendarWithImageDuplicates.layoutItems));

getSvg();

function getSvg() {
  store.getCalendarSvg(calendarId, 'cover').then((data) => {
    if (data) {
      svgCover.value = data.svgPages[1]?.Production || '<text x=10 y=20>No Image</text>';
    }
  });
  store.getCalendarSvg(calendarId, 'back').then((data) => {
    if (data) {
      svgBack.value = data.svgPages[1]?.Production || '<text x=10 y=20>No Image</text>';
    }
  });
  store.getCalendarSvg(calendarId, 'months').then((data) => {
    if (data) {
      svgMonths.value = data.svgPages;
    }
  });
}

function getPdf() {
  pdfUrl.value = '';
  pdfPath.value = '';
  store.getCalendarPdf(calendarId).then((data) => {
    pdfUrl.value = data.blobUrl;
    pdfPath.value = data.blobFilePath;
  });
}

function createProduct() {
  store.createProduct(calendarId);
}

function refreshProduct() {
  store.createProduct(calendarId);
}

function textEditClose(content: string, id: string) {
  store.calendarWithImageDuplicates[id] = content;
  store.saveCalendarWithDuplicates(store.calendarWithImageDuplicates).then(() => getSvg());
}
function textEditLayoutItemClose(content: string, id: string) {
  var layoutItem = store.calendarWithImageDuplicates.layoutItems.find((i) => i.name == id);
  layoutItem.content = content;
  store.saveCalendarLayoutItem(layoutItem).then(() => getSvg());
}
function selectEditLayoutItemYearClose(content: any, id: string) {
  var layoutItem = store.calendarWithImageDuplicates.layoutItems.find((i) => i.name == id);
  layoutItem.content = content.value.toString();
  store.saveCalendarLayoutItem(layoutItem).then(() => getSvg());
}
function selectEditLayoutItemFontClose(fontId, id) {
  var layoutItem = store.calendarWithImageDuplicates.layoutItems.find((i) => i.name == id);
  layoutItem.fontId = fontId;
  store.saveCalendarLayoutItem(layoutItem).then(() => getSvg());
}
function textEditLayoutItemFontSizeClose(size: string, id) {
  var layoutItem = store.calendarWithImageDuplicates.layoutItems.find((i) => i.name == id);
  layoutItem.fontSize = parseInt(size);
  store.saveCalendarLayoutItem(layoutItem).then(() => getSvg());
}
function selectEditChangeAllClose(fontId, id) {
  store.calendarWithImageDuplicates.layoutItems.forEach((layoutItem) => {
    layoutItem.fontId = fontId;
    store.saveCalendarLayoutItem(layoutItem).then(() => getSvg());
  });
}
function layoutItemDelete(layoutItemId: number) {
  store.deleteCalendarLayoutItem(layoutItemId).then(() => getSvg());
}
function layoutItemAdd(layoutItemName) {
  store.addCalendarLayoutItem(layoutItemName).then(() => getSvg());
}
</script>

<template>
  <div v-if="store.calendarWithImageDuplicates.images" class="calendar container mt-5">
    <div class="row mb-3">
      <div class="col-12 col-lg-5 col-xxl-4">
        <div class="wrap-layout">
          <svg class="cover" v-html="svgCover"></svg>
          <svg class="back" v-html="svgBack"></svg>
        </div>
      </div>
      <div class="col-12 col-lg-7 col-xxl-8 d-none d-sm-block">
        <table class="table table-sm table-borderless">
          <tbody>
            <tr>
              <th>Owner</th>
              <td>{{ store.calendarWithImageDuplicates.identityName }}</td>
              <td>
                <SelectEdit :name-values="store.vendors" :id="'vendorId'" :selected-value="store.calendarWithImageDuplicates.vendorId" @close="textEditClose"></SelectEdit>
              </td>
              <td>
                <SelectEdit :name-values="store.permissions" :id="'permission'" :selected-value="store.calendarWithImageDuplicates.permission" @close="textEditClose"></SelectEdit>
              </td>
              <td>
                <SelectEdit :name-values="store.statuses" :id="'status'" :selected-value="store.calendarWithImageDuplicates.status" @close="textEditClose"></SelectEdit>
              </td>
            </tr>
            <tr>
              <th>Notes</th>
              <td colspan="4">
                <TextEdit :text="store.calendarWithImageDuplicates.notes" :id="'notes'" @close="textEditClose"></TextEdit>
              </td>
            </tr>
            <template v-if="store.calendarWithImageDuplicates.productId > 0">
              <tr>
                <th>
                  Product<br /><small>{{ store.calendarWithImageDuplicates.productId }}</small>
                </th>
                <td colspan="4">
                  <strong>{{ store.calendarWithImageDuplicates.productTitle }}</strong
                  ><br />
                  <span>{{ store.calendarWithImageDuplicates.productTagline }}</span
                  ><br />
                  <small v-if="store.calendarWithImageDuplicates.productEan > 0">EAN: {{ store.calendarWithImageDuplicates.productEan }}</small>
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <a
                    class="btn btn-sm btn-outline-secondary ms-2"
                    :href="'https://integration.foundimagepress.com/Products/Edit/' + store.calendarWithImageDuplicates.productId"
                    target="_blank"
                    >VIEW</a
                  >
                  <a class="btn btn-sm btn-outline-secondary ms-2" @click="refreshProduct">REFRESH</a>
                </td>
                <td colspan="3" class="text-end">
                  <button class="btn btn-sm btn-outline-secondary ms-2" @click="getPdf">PDF</button>
                  <a :href="pdfUrl" target="_blank" class="ms-2">{{ pdfPath }}</a>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <th>Product</th>
                <td colspan="3">
                  <button class="btn btn-sm btn-outline-secondary" @click="createProduct">CREATE</button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <table class="layout-items table table-sm table-borderless mt-4">
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td>
                <SelectEdit
                  :always-editable="true"
                  :name-values="store.fonts"
                  :selected-value="0"
                  :placeholder="'Change all fonts...'"
                  style="width: 160px"
                  @close="selectEditChangeAllClose"
                ></SelectEdit>
              </td>
              <td></td>
              <td style="width: 70%"></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in store.calendarWithImageDuplicates.layoutItems" :key="item.id">
              <th>{{ item.name }}</th>

              <td>
                <span v-if="item.type & store.layoutItemTypes.IsOptional">
                  <a class="remove" v-tooltip="'Remove ' + item.name" @click="layoutItemDelete(item.id)"><i class="bi-eraser"></i></a>
                </span>
              </td>

              <td v-if="(item.type & store.layoutItemTypes.HasFont) === store.layoutItemTypes.HasFont">
                <SelectEdit
                  :name-values="store.fonts"
                  :id="item.name"
                  :selected-value="item.fontId"
                  :always-editable="true"
                  style="width: 160px"
                  @close="selectEditLayoutItemFontClose"
                >
                </SelectEdit>
              </td>
              <td v-else></td>

              <td v-if="(item.type & store.layoutItemTypes.HasFontSize) === store.layoutItemTypes.HasFontSize">
                <TextEdit style="width: 50px" :input-type="'number'" :text="item.fontSize.toString()" :id="item.name" @close="textEditLayoutItemFontSizeClose"></TextEdit>
              </td>
              <td v-else></td>

              <template v-if="(item.type & store.layoutItemTypes.HasValue) === store.layoutItemTypes.HasValue">
                <td v-if="(item.type & store.layoutItemTypes.StringValue) === store.layoutItemTypes.StringValue">
                  <TextEdit
                    :style="'font-family:' + item.font.fontFamily + ';' + item.font.fontOther"
                    :text="item.content"
                    :id="item.name"
                    @close="textEditLayoutItemClose"
                  ></TextEdit>
                </td>
                <td v-else-if="(item.type & store.layoutItemTypes.LookupIntegerValue) === store.layoutItemTypes.LookupIntegerValue">
                  <SelectEdit
                    style="width: 80px"
                    :name-values="store.calendarYears.years"
                    :id="item.name"
                    :selected-value="item.content"
                    :always-editable="true"
                    @close="selectEditLayoutItemYearClose"
                  ></SelectEdit>
                </td>
                <td v-if="(item.type & store.layoutItemTypes.LookupStringValue) === store.layoutItemTypes.LookupStringValue">
                  <TextEdit :text="item.content" :id="item.name" @close="textEditLayoutItemClose"></TextEdit>
                </td>
              </template>
              <td v-else></td>
            </tr>
          </tbody>
          <tfoot v-show="toggleStore.isOpen">
            <tr v-for="item in store.customLayoutItems">
              <th>
                {{ item.name }}
              </th>
              <th>
                <a class="remove" v-tooltip="'Add ' + item.name" @click="layoutItemAdd(item.name)"><i class="bi-plus"></i></a>
              </th>
            </tr>
          </tfoot>
          <tfoot v-show="!toggleStore.isOpen">
            <tr>
              <td><a class="btn btn-sm btn-outline-secondary" @click="toggleStore.toggle()">CUSTOMIZE</a></td>
            </tr>
          </tfoot>
        </table>

        <div class="calendar-duplicates d-none d-md-block">
          <table class="table table-sm table-light table-borderless">
            <thead>
              <tr>
                <th colspan="3"></th>
                <th>Duplicates</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="image in store.calendarWithImageDuplicates.images" :key="image.id">
                <td>{{ image.positionName }}</td>
                <td style="white-space: nowrap">{{ image.artCode }}</td>
                <td>{{ image.caption }}</td>
                <td>
                  <div class="duplicates" v-for="duplicate in image.duplicates">
                    <div class="month-duplicate" v-if="duplicate.calendarId == store.calendarWithImageDuplicates.id && duplicate.position != image.position">
                      {{ duplicate.positionName }}
                    </div>
                    <div class="calendar-duplicate" v-if="duplicate.calendarId != store.calendarWithImageDuplicates.id">
                      <a @click="$router.push({ name: 'calendar', params: { id: duplicate.calendarId } })">
                        <i class="bi-exclamation-triangle-fill ms-1"></i>{{ duplicate.calendarTitle }} {{ duplicate.calendarYear }} - {{ duplicate.positionName }}
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="wrap-layout">
          <template v-for="svg in svgMonths">
            <svg class="month" v-html="svg.Production"></svg>
          </template>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="row mt-3">
      <div class="col">
        <div class="d-block d-sm-none">
          <span class="btn btn-sm btn-outline-secondary disabled">X-Small &lt;576</span>
        </div>
        <div class="d-none d-sm-block d-md-none">
          <span class="btn btn-sm btn-outline-secondary disabled">Small &gt;=576</span>
        </div>
        <div class="d-none d-md-block d-lg-none">
          <span class="btn btn-sm btn-outline-secondary disabled">Medium &gt;=768</span>
        </div>
        <div class="d-none d-lg-block d-xl-none">
          <span class="btn btn-sm btn-outline-secondary disabled">Large &gt;=992</span>
        </div>
        <div class="d-none d-xl-block d-xxl-none">
          <span class="btn btn-sm btn-outline-secondary disabled">X-Large &gt;=1200</span>
        </div>
        <div class="d-none d-xxl-block">
          <span class="btn btn-sm btn-outline-secondary disabled">XX-Large &gt;=1400</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$layout-color: #ccc;
$layout-color: transparent;

.table {
  font-family: 'Cabin Condensed';
}

.calendar {
  margin-top: 1rem;

  .calendar-duplicates {
    padding: 0.5rem;
    background-color: #fff;
    font-size: 0.7rem;
    table {
      margin: 0;

      .tr-cover td {
        font-weight: bold;
      }

      tr.hover td {
        background-color: blanchedalmond;
        cursor: default;
      }

      .duplicates {
        .calendar-duplicate {
          font-size: 0.7rem;

          a {
            color: rgb(185, 102, 0);
            cursor: pointer;

            &:hover {
              color: rgb(255, 156, 35);
            }
          }
          i {
            margin-right: 0.125rem;
          }
        }
      }
    }
  }
}

.layout-items {
  a.remove {
    cursor: pointer;
    color: #333;

    &:hover {
      color: red;
    }
  }
}

.wrap-layout {
  display: flex;
  flex-wrap: wrap;
}

svg {
  background-color: #fff;
}

svg.cover,
svg.back {
  margin-bottom: 1rem;
}

svg.month {
  margin-bottom: 1rem;
}

@media (min-width: 0) {
  .wrap-layout {
    justify-content: center;
  }
  svg.cover,
  svg.back,
  svg.month {
    height: 286px;
    width: 300px;
  }
}
// small
@media (min-width: 576px) {
  .wrap-layout {
    justify-content: space-between;
  }
  svg.cover,
  svg.back {
    height: 260px;
    width: 250px;
  }
  svg.month {
    height: 125px;
    width: 120px;
  }
}
// medium
@media (min-width: 768px) {
  svg.cover,
  svg.back {
    height: 349px;
    width: 335px;
  }
  svg.month {
    height: 167px;
    width: 160px;
  }
}
// large
@media (min-width: 992px) {
  svg.cover,
  svg.back {
    height: 396px;
    width: 380px;
    margin: 0 1rem 1rem 0;
  }
  svg.month {
    height: 219px;
    width: 210px;
  }
}
// x-large
@media (min-width: 1200px) {
  svg.cover,
  svg.back {
    height: 469px;
    width: 450px;
    margin: 0 1rem 1rem 0;
  }
  svg.month {
    height: 271px;
    width: 260px;
  }
}
// xx-large
@media (min-width: 1400px) {
  svg.cover,
  svg.back {
    height: 438px;
    width: 420px;
  }
  svg.month {
    height: 313px;
    width: 300px;
  }
}
</style>
