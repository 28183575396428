<script setup lang="ts">
import type { ICollection, Image } from '@/types/image';
import { watch, ref, onMounted } from 'vue';
import { useCalendarsStore } from '@/stores/calendars';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import { DragCol, DragRow } from 'vue-resizer';

import ModalConfirm from '@/components/ModalConfirm.vue';
import ModalCreateLightbox from '@/components/ModalCreateLightbox.vue';
import ModalCreateCalendar from '@/components/ModalCreateCalendar.vue';
import Categories from '@/components/Categories.vue';
import ModalImage from '@/components/ModalImage.vue';
import SearchMode from '@/components/SearchMode.vue';
import SearchSummary from '@/components/SearchSummary.vue';
import LayoutToggle from '@/components/LayoutToggle.vue';
import AnyAll from '@/components/AnyAll.vue';
import Orientation from '@/components/Orientation.vue';
import ItemInfo from '@/components/ItemInfo.vue';
import SearchInput from '@/components/SearchInput.vue';
import MiniImages from '@/components/MiniImages.vue';
import TextEdit from '@/components/TextEdit.vue';
import SelectEdit from '@/components/SelectEdit.vue';
import Help from '@/components/Help.vue';

interface IDragPayload {
  from: string;
  image: Image;
}

const store = useCalendarsStore();
const auth = useAuthStore();
const modal = useModalStore();

var payload = {} as IDragPayload;

const toolbar = ref(null);
const dragEnterCreateLightbox = ref(false);
const dragEnterCreateCalendar = ref(false);
const draggingItemNewCollection = ref({} as Image);

const showSectionHeader = ref({calendars: false, lightboxes: false});

const calendarsShowShared = ref(false);
const calendarsSelectedYear = ref(2025);
const calendarsSelectedText = ref('');

const lightboxesShowShared = ref(false);
const lightboxesSelectedText = ref('');

const dragStatus = ref({
  scratch: {
    canDrop: true,
    dragEnter: false,
  },
  lightbox: {
    canDrop: true,
    dragEnter: false,
  },
  calendar: {
    canDrop: true,
    dragEnter: false,
  },
  lightboxes: {
    canDrop: true,
    dragEnter: false,
  },
  calendars: {
    canDrop: true,
    dragEnter: false,
  },
});

function resetDragStatus() {
  dragStatus.value.scratch.dragEnter = false;
  dragStatus.value.lightbox.dragEnter = false;
  dragStatus.value.calendar.dragEnter = false;
  dragStatus.value.calendars.dragEnter = false;
  dragStatus.value.lightboxes.dragEnter = false;
}

watch(
  () => store.searchText,
  () => {
    store.searchNow();
  }
);

watch(
  () => store.searchPageSize,
  (newValue, oldValue) => {
    store.searchNow();
  }
);

watch(
  () => store.anyAll,
  (newValue, oldValue) => {
    store.searchNow();
  }
);

watch(
  () => store.orientation,
  (newValue, oldValue) => {
    store.searchNow();
  }
);

watch(
  () => store.searchMode,
  (newValue, oldValue) => {
    store.searchNow();
  }
)

// https://github.com/mdn/dom-examples/blob/main/resize-observer/resize-observer-border-radius.html
const resizeObserver = new ResizeObserver((entries) => {
  if (toolbar.value && toolbar.value.clientWidth < 790) store.searchPanelSize = 'small';
  else if (toolbar.value && toolbar.value.clientWidth < 920) store.searchPanelSize = 'medium';
  else store.searchPanelSize = 'large';
});

onMounted(() => {
  resizeObserver.observe(toolbar.value);
});

function calendars() {
  return store.calendars
    .filter((c) => calendarsShowShared.value == true || c.identityName == auth.account.username)
    .filter((c) => calendarsSelectedYear.value == 0 || c.year == calendarsSelectedYear.value)
    .filter((c) => calendarsSelectedText.value == '' || c.title.toLowerCase().includes(calendarsSelectedText.value.toLowerCase()));
}

function lightboxes() {
  return store.lightboxes
    .filter((lb) => lightboxesShowShared.value == true || lb.identityName == auth.account.username)
    .filter((lb) => lightboxesSelectedText.value == '' || lb.title.toLowerCase().includes(lightboxesSelectedText.value.toLowerCase()));
}

function dragover(e: any, area: string, collection?: ICollection) {
  if (collection == null || collection.identityName == auth.account.username || collection.permission == 1) {
    e.preventDefault();
  }
}

function dragenter(e: any, area: string, collection?: ICollection) {
  if (collection == null || collection.identityName == auth.account.username || collection.permission == 1) {
    e.preventDefault();

    if (e.target.classList.contains(area + '-drop-zone')) {
      dragStatus.value[area].dragEnter = true;
    }
  }
}

function dragleave(e: any, area: string) {
  if (e.target.classList.contains(area + '-drop-zone')) {
    dragStatus.value[area].dragEnter = false;
  }
}

function dragenterCollection(e: any, collection: ICollection) {
  if (collection.identityName != auth.account.username && collection.permission != 1) {
    return;
  }
  if (collection.collectionType == 'lightbox') {
    store.loadLightbox(collection.id);
    collection.dragEnter = true;
  } else if (collection.collectionType == 'calendar') {
    store.loadCalendar(collection.id);
  }
}
function dragleaveCollection(e: any, collection: ICollection) {
  collection.dragEnter = false;
}

function dragenterCalendarItem(e: any, item: Image, collection: ICollection) {
  if (collection.identityName != auth.account.username && collection.permission != 1) {
    return;
  }
  item.dragEnter = true;
}
function dragleaveCalendarItem(e: any, item: Image) {
  item.dragEnter = false;
}

function dragenterCreateCollection(e: any, collectionType: string) {
  if (collectionType == 'lightbox') {
    dragEnterCreateLightbox.value = true;
  } else if (collectionType == 'calendar') {
    dragEnterCreateCalendar.value = true;
  }
}

function dragleaveCreateCollection() {
  dragEnterCreateLightbox.value = false;
  dragEnterCreateCalendar.value = false;
}

function setLayout(section: string, value: string) {
  store.layout[section] = 'image-' + value;
}

function dragstart(e: any) {
  payload = {
    from: e.target.dataset.from,
    image: store[e.target.dataset.from][e.target.dataset.index],
  };
  payload.image.dragging = true;
  highlightToggle(payload.image);
}

function dragend(e: any) {
  resetDragEffects();
}

function resetDragEffects() {
  store.searchImages
    .concat(store.scratchImages)
    .concat(store.lightboxImages)
    .concat(store.calendarImages)
    .forEach((i) => {
      i.highlight = false;
      i.dragging = false;
      i.dragEnter = false;
    });
}

function highlightToggle(srcImage?: Image) {
  store.searchImages
    .concat(store.scratchImages)
    .concat(store.lightboxImages)
    .concat(store.calendarImages)
    .filter((i) => i.imageId == srcImage.imageId)
    .forEach((i) => {
      i.highlight = true;
    });
}

function dropLightbox(e: any, lightboxId: number) {
  e.preventDefault();

  resetDragStatus();
  dragleaveCreateCollection();

  if (lightboxId > 0) {
    draggingItemNewCollection.value = null;
    store.addLightboxImage(payload.image, lightboxId);
    return;
  }

  draggingItemNewCollection.value = payload.image;
  modal.open('modalCreateLightbox');
}

function dropScratch(e: any) {
  e.preventDefault();
  store.addScratch(payload.image);
  resetDragStatus();
}

function dropNewCalendar(e: any) {
  e.preventDefault();

  resetDragStatus();
  dragleaveCreateCollection();
  draggingItemNewCollection.value = payload.image;
  modal.open('modalCreateCalendar');
}

function dropCalendarItem(e: any) {
  e.preventDefault();

  var destImage = store.calendarImages[e.target.dataset.index];
  //  [...store.calendarImages.slice(1), ...store.calendarImages.slice(0,1)][e.target.dataset.index];

  if (payload.from === 'calendarImages') {
    const swap = destImage.position != 0 && payload.image.position != 0; // month not cover

    store.calendarImages.filter((i) => i.imageId == payload.image.imageId && i.position > 0 && i.position != payload.image.position).forEach((i) => resetCalendarImage(i));

    store.setSwapCalendarImage(payload.image, destImage, swap);
  } else {
    // Add replaced image to scratch
    store.addScratch(destImage);

    if (destImage.position > 0) {
      store.calendarImages
        .filter((i) => i.imageId == payload.image.imageId && i.position > 0)
        .forEach((i) => {
          resetCalendarImage(i);
        });
    }
    store.setSwapCalendarImage(payload.image, destImage);
  }

  store.updateCalendarImages(store.calendarImages);
}
function removeFromLightbox(index: number) {
  var srcImage = store.lightboxImages[index];
  store.removeLightboxImage(srcImage, store.lightbox.id);
}

function modalCreateLightboxClose(confirm: boolean, lightboxName: string) {
  if (confirm) {
    store.createLightbox(lightboxName, draggingItemNewCollection.value);
  }
  draggingItemNewCollection.value = null;
}

function modalCreateCalendarClose(confirm: boolean, calendar: ICollection) {
  if (confirm) {
    store.createCalendar(calendar, draggingItemNewCollection.value);
  }
  draggingItemNewCollection.value = null;
}

function resetCalendarImage(srcImage: Image) {
  srcImage.artCode = '';
  srcImage.imageId = 0;
  srcImage.caption = '';
}

function removeCalendarImage(index: number) {
  var srcImage = store.calendarImages[index];
  if (srcImage) {
    resetCalendarImage(srcImage);
    store.updateCalendarImages(store.calendarImages);
  }
}

function addSearchTerm(term: string) {
  var space = store.searchText.length > 0 ? ' ' : '';
  store.searchText += space + term;
}

function textEditLightboxNameClose(lightboxTitle) {
  store.lightbox.title = lightboxTitle;
  store.saveLightbox(store.lightbox);
}

function textEditCalendarNameClose(calendarTitle) {
  store.calendar.title = calendarTitle;
  store.saveCalendar(store.calendar);
}

function selectEditLightboxPermissionClose(permission: number) {
  store.lightbox.permission = permission;
  store.saveLightbox(store.lightbox);
}

function selectEditCalendarYearClose(year: number) {
  store.calendar.year = year;
  store.saveCalendar(store.calendar);
}

function selectEditCalendarStatusClose(status: number) {
  store.calendar.status = status;
  store.saveCalendar(store.calendar);
}

function deleteCalendar(confirm: boolean) {
  if (confirm) {
    store.deleteCalendar(store.calendar.id);
  }
}

function deleteLightbox(confirm: boolean) {
  if (confirm) {
    store.deleteLightbox(store.lightbox.id);
  }
}

function DisplayPermissionIcon(collection: ICollection) {
  // If not creating, show status
  if (collection.statusName != 'Creating') {
    var status = store.statuses.find((s) => s.value == collection.status);
    if (status == null) {
      console.log('Status not found:', collection.statusName);
    }
    return status['icon'];
  }
  // else show permissions
  var permission = store.permissions.find((p) => p.value == collection.permission);
  if (permission == null) {
    return '';
  }
  return permission['icon'];
}
</script>

<template>
  <div class="content">
    <DragRow height="100%" width="100%" sliderColor="#ddd" sliderBgColor="#8b4717" sliderHoverColor="#ffffff" sliderBgHoverColor="#955223" :sliderWidth="12" :topPercent="30">
      <template #top>
        <DragCol height="100%" width="100%" sliderColor="#ddd" sliderBgColor="#8b4717" sliderHoverColor="#ffffff" sliderBgHoverColor="#955223" :sliderWidth="12" :leftPercent="70">
          <!-- Search -->
          <template #left>
            <Categories></Categories>
            <help></help>

            <div class="section-header search top">
              <div class="section-title">Search</div>
              <SearchInput></SearchInput>
            </div>
            <div class="section-header search" ref="toolbar" :class="store.searchPanelSize">
              <SearchMode></SearchMode>
              <Orientation :show-mode="store.searchMode == 'expert' || store.searchMode == 'artCodes' ? 'inactive' : 'active'"></Orientation>
              <AnyAll :show-mode="store.searchMode == 'basic' ? 'active' : 'inactive'"></AnyAll>
              <SearchSummary></SearchSummary>
              <layout-toggle :section="store.layout.search" @layoutChanged="(layout) => setLayout('search', layout)" />
            </div>

            <!-- Search Images -->
            <div class="image-layout" :class="store.layout.search" @dragenter="($event) => dragenter($event, 'lightbox')" @dragleave="($event) => dragleave($event, 'lightbox')">
              <div v-for="(item, index) in store.searchImages" :key="item.imageId" class="item">
                <div
                  :data-index="index"
                  :data-from="'searchImages'"
                  :data-image-id="item.imageId"
                  @dragstart="dragstart"
                  @dragend="dragend"
                  draggable="true"
                  class="thumbnail"
                  :class="{ 'duplicate-highlight': item.highlight, dragging: item.dragging }"
                >
                  <div :data-index="index" class="thumbnail-image" :style="'background-image: url(https://image.foundimagepress.com/' + item.artCode">
                    <div class="btn-icon-wrapper">
                      <button class="btn btn-secondary btn-icon-overlay" @click="modal.open('modalImage', item)" v-tooltip="'View'">
                        <i class="bi-arrows-fullscreen"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <ItemInfo :item="item" :show="store.layout.search == 'image-list'" @term-clicked="addSearchTerm"></ItemInfo>
              </div>
            </div>
          </template>

          <!-- Scratch -->
          <template #right>
            <div class="section-header section-header-scratch">
              <div class="section-title">My Stash</div>
              <button @click="store.clearScratch" class="btn btn-sm btn-outline-secondary" v-tooltip="'Clear'">
                <i class="bi-eraser-fill"></i>
              </button>
            </div>
            <div class="section-header section-header-scratch">
              <div></div>
              <div class="layout-toggle-wrapper">
                <layout-toggle :section="store.layout.scratch" @layoutChanged="(layout) => setLayout('scratch', layout)" />
              </div>
            </div>

            <div
              class="image-layout image-layout-scratch scratch-drop-zone"
              :class="[store.layout.scratch, { 'drag-enter': dragStatus.scratch.dragEnter }]"
              @drop="dropScratch"
              @dragover="($event) => dragover($event, 'scratch')"
              @dragenter="($event) => dragenter($event, 'scratch')"
              @dragleave="($event) => dragleave($event, 'scratch')"
            >
              <div v-for="(item, index) in store.scratchImages" :key="item.imageId" class="item">
                <div
                  :data-index="index"
                  :data-from="'scratchImages'"
                  :data-image-id="item.imageId"
                  @dragstart="dragstart"
                  @dragend="dragend"
                  draggable="true"
                  class="thumbnail"
                  :class="{
                    'duplicate-highlight': item.highlight,
                    dragging: item.dragging,
                  }"
                >
                  <div :data-index="index" class="thumbnail-image" :style="'background-image: url(https://image.foundimagepress.com/' + item.artCode">
                    <div class="btn-icon-wrapper">
                      <button class="btn btn-secondary btn-icon-overlay" @click="modal.open('modalImage', item)" v-tooltip="'View'">
                        <i class="bi-arrows-fullscreen"></i>
                      </button>
                      <button class="btn btn-secondary btn-icon-overlay" @click="store.removeScratch(item)" v-tooltip="'Remove'">
                        <i class="bi-eraser-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <ItemInfo :item="item" :show="store.layout.scratch == 'image-list'" @term-clicked="addSearchTerm"></ItemInfo>
              </div>
              <div class="thumbnail drag-empty-target">
                <div class="thumbnail-image"></div>
              </div>
            </div>
          </template>
        </DragCol>
      </template>

      <template #bottom>
        <DragCol
          height="100%"
          width="100%"
          sliderColor="#ddd"
          sliderBgColor="#8b4717"
          sliderHoverColor="#ffffff"
          sliderBgHoverColor="#955223"
          :sliderWidth="12"
          :leftPercent="50"
        >
          <!-- 
            Lightboxes 
          -->
          <template #left>

            <Transition name="fade">
            <div v-if="showSectionHeader.lightboxes" class="absolute">

            <div class="section-header section-header-lightboxes">
              <div class="section-title">
                  <div class="controls">
                  <a class="hover" v-tooltip="'Hide Collections'" @click="showSectionHeader.lightboxes = !showSectionHeader.lightboxes">Collections&nbsp;<i class="bi-caret-down-fill"></i></a>
                </div>
                </div>
              <div class="section-buttons">
                <div class="top">
                  <div class="form-check form-switch ms-2">
                    <input v-model="lightboxesShowShared" class="form-check-input" type="checkbox" role="switch" id="lightboxesShowShared" />
                    <label class="form-check-label" for="lightboxesShowShared">Include Shared</label>
                  </div>
                  <div class="input-group input-group-sm ms-3">
                    <span class="input-group-text"><i class="bi-search"></i></span>
                    <input class="form-control form-control-sm" v-model="lightboxesSelectedText" />
                    <span class="input-group-text" @click="lightboxesSelectedText = ''"><i class="bi-x"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Lightbox Icons -->
            <div class="collection-icons">
              <div
                v-for="lb in lightboxes()"
                :key="lb.id"
                :value="lb.id"
                class="collection-icon"
                :class="{ active: store.lightbox.id === lb.id, 'drag-enter-collection': lb.dragEnter }"
                @drop="dropLightbox($event, lb.id)"
                @dragover="($event) => dragover($event, 'lightboxes', lb)"
                @dragenter="($event) => dragenterCollection($event, lb)"
                @dragleave="($event) => dragleaveCollection($event, lb)"
                @click="store.loadLightbox(lb.id)"
              >
                <mini-images :collection="lb"></mini-images>
                <div class="title">{{ lb.title }}</div>
                <div class="owner" v-show="lb.identityName != auth.account.username">{{ lb.identityName.split('@')[0] }} <i :class="DisplayPermissionIcon(lb)"></i></div>
              </div>
              <div
                class="collection-icon"
                :class="{ 'drag-enter-create': dragEnterCreateLightbox }"
                @click="modal.open('modalCreateLightbox')"
                @dragover="($event) => dragover($event, 'lightboxes')"
                @drop="dropLightbox($event, 0)"
                @dragenter="($event) => dragenterCreateCollection($event, 'lightbox')"
                @dragleave="dragleaveCreateCollection"
              >
                <MiniImages></MiniImages>
              </div>
            </div>

            <!-- Selected Lightbox  -->
            <div class="section-header section-header-lightbox open">
              <div v-if="store.lightbox.identityName === auth.account?.username" class="section-title">
                <TextEdit class="mb-1" :text="store.lightbox.title" @close="textEditLightboxNameClose"></TextEdit>
                <SelectEdit :name-values="store.permissions" :selected-value="store.lightbox.permission" @close="selectEditLightboxPermissionClose"></SelectEdit>
              </div>
              <div v-else class="section-title shared-lightbox">
                <div>{{ store.lightbox.title }}</div>
                <div class="shared-by">
                  SHARED BY <strong>{{ store.lightbox.identityName }} </strong>
                  <i :class="{ 'bi-lock-fill': store.lightbox.permissionName == 'SharedReadOnly', 'bi-unlock-fill': store.lightbox.permissionName == 'SharedReadWrite' }"></i>
                </div>
              </div>
              <div class="section-buttons">
                <button
                  v-show="store.lightbox.id != null"
                  @click="modal.open('modalDeleteCollection')"
                  class="btn btn-sm btn-outline-secondary ms-3"
                  v-tooltip="'Delete Collection'"
                >
                  <i class="bi-trash"></i>
                </button>

                <layout-toggle :section="store.layout.lightbox" @layoutChanged="(layout) => setLayout('lightbox', layout)" />
              </div>
            </div>

            </div>

            <div v-else class="absolute">
              <div class="section-header section-header-lightbox closed">
                <div class="section-title">
                  <div class="controls">
                    <a class="hover" v-tooltip="'Show Collections'" @click="showSectionHeader.lightboxes = !showSectionHeader.lightboxes">Collections <i class="bi-caret-right-fill"></i></a>
                    <TextEdit :text="store.lightbox.title" @close="textEditLightboxNameClose"></TextEdit>
                <SelectEdit :name-values="store.permissions" :selected-value="store.lightbox.permission" @close="selectEditLightboxPermissionClose"></SelectEdit>                    
                  </div>
                </div>
                <div class="section-buttons">                  
                  <layout-toggle :section="store.layout.calendar" @layoutChanged="(layout) => setLayout('lightbox', layout)" />
                </div>
              </div>
            </div>
            </Transition>



            <!-- Lightbox Images -->

            <div
              class="image-layout image-layout-lightbox lightbox-drop-zone"
              :class="[store.layout.lightbox, showSectionHeader.lightboxes ? 'open' : 'closed', { 'drag-enter': dragStatus.lightbox.dragEnter }]"
              v-if="store.lightbox != null"
              @drop="dropLightbox($event, store.lightbox.id)"
              @dragover="($event) => dragover($event, 'lightbox', store.lightbox)"
              @dragenter="($event) => dragenter($event, 'lightbox', store.lightbox)"
              @dragleave="($event) => dragleave($event, 'lightbox')"
            >
              <div v-for="(item, index) in store.lightboxImages" :key="item.imageId" class="item">
                <div
                  :data-index="index"
                  :data-from="'lightboxImages'"
                  :data-image-id="item.imageId"
                  @dragstart="dragstart"
                  @dragend="dragend"
                  draggable="true"
                  class="thumbnail"
                  :class="{ 'duplicate-highlight': item.highlight, dragging: item.dragging }"
                >
                  <div :data-index="index" class="thumbnail-image" :style="'background-image: url(https://image.foundimagepress.com/' + item.artCode">
                    <div class="btn-icon-wrapper">
                      <button class="btn btn-secondary btn-icon-overlay" @click="modal.open('modalImage', item)" v-tooltip="'View'">
                        <i class="bi-arrows-fullscreen"></i>
                      </button>
                      <button class="btn btn-secondary btn-icon-overlay" @click="removeFromLightbox(index)" v-tooltip="'Remove'">
                        <i class="bi-eraser-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <ItemInfo :item="item" :show="store.layout.lightbox == 'image-list'" @term-clicked="addSearchTerm"></ItemInfo>
              </div>
              <div class="thumbnail drag-empty-target">
                <div class="thumbnail-image"></div>
              </div>
            </div>
          </template>

          <!-- 
            Calendars
          -->
          <template #right>

           <Transition name="fade">
            <div v-if="showSectionHeader.calendars" class="absolute">
              
              <div class="section-header section-header-calendars">
                <div class="section-title">
                  <div class="controls">
                  <a class="hover" v-tooltip="'Hide Calendars'" @click="showSectionHeader.calendars = !showSectionHeader.calendars">Calendars&nbsp;<i class="bi-caret-down-fill"></i></a>
                </div>
                </div>
                <div class="section-buttons">
                  <div class="top">
                    <div class="form-check form-switch ms-2">
                      <input v-model="calendarsShowShared" class="form-check-input" type="checkbox" role="switch" id="calendarsShowShared" />
                      <label class="form-check-label" for="calendarsShowShared">Include Shared</label>
                    </div>
                    <div class="input-group input-group-sm me-3">
                      <span class="input-group-text"><i class="bi-search"></i></span>
                      <input class="form-control form-control-sm" v-model="calendarsSelectedText" />
                      <span class="input-group-text" @click="calendarsSelectedText = ''"><i class="bi-x"></i></span>
                    </div>
                    <select v-model="calendarsSelectedYear" class="form-select form-select-sm">
                      <option :value="0">ALL</option>
                      <option v-for="c in store.calendarYears.years" :value="c.value">{{ c.name }}</option>
                    </select>
                  </div>
                </div>
              </div>


              <!-- Calendar Icons -->
              <div class="collection-icons">
                <div
                  v-for="cal in calendars()"
                  :key="cal.id"
                  :value="cal.id"
                  class="collection-icon"
                  :class="{ active: store.calendar.id === cal.id, 'drag-enter-collection': cal.dragEnter }"
                  @dragenter="($event) => dragenterCollection($event, cal)"
                  @dragleave="($event) => dragleaveCollection($event, cal)"
                  @click="store.loadCalendar(cal.id)"
                >
                  <mini-images :collection="cal"></mini-images>
                  <div class="title">
                    {{ cal.title }}<br /><small>{{ cal.year }}</small>
                  </div>
                  <div class="owner" v-show="cal.identityName != auth.account.username">{{ cal.identityName.split('@')[0] }} <i :class="DisplayPermissionIcon(cal)"></i></div>
                </div>
                <div
                  class="collection-icon"
                  :class="{ 'drag-enter-create': dragEnterCreateCalendar }"
                  @click="modal.open('modalCreateCalendar')"
                  @drop="dropNewCalendar"
                  @dragover="($event) => dragover($event, 'calendars')"
                  @dragenter="($event) => dragenterCreateCollection($event, 'calendar')"
                  @dragleave="dragleaveCreateCollection"
                >
                  <MiniImages></MiniImages>
                </div>
              </div>

              <!-- Selected Calendar -->
              <div class="section-header section-header-calendar open">
                <div class="section-title">
                  <div class="controls mb-1">
                    <TextEdit :text="store.calendar.title" @close="textEditCalendarNameClose"></TextEdit>
                    <a class="static-row hover" v-tooltip="'View Calendar'" @click="$router.push({ name: 'calendar', params: { id: store.calendar.id } })"
                      ><i class="bi-calendar2-month"></i
                    ></a>
                  </div>
                  <div class="controls">
                    <SelectEdit :name-values="store.calendarYears.years" :selected-value="store.calendar.year" @close="selectEditCalendarYearClose"></SelectEdit>
                    <SelectEdit :name-values="store.statuses" :selected-value="store.calendar.status" @close="selectEditCalendarStatusClose"></SelectEdit>
                  </div>
                </div>
                <div class="section-buttons">
                  <button v-show="store.calendar.id != null" @click="modal.open('modalDeleteCalendar')" class="btn btn-sm btn-outline-secondary" v-tooltip="'Delete Calendar'">
                    <i class="bi-trash"></i>
                  </button>
                  <layout-toggle :section="store.layout.calendar" @layoutChanged="(layout) => setLayout('calendar', layout)" />
                </div>
              </div>
            </div>

            <div v-else class="absolute">
              <div class="section-header section-header-calendar closed">
                <div class="section-title">
                  <div class="controls">
                    <a class="hover" v-tooltip="'Show Calendars'" @click="showSectionHeader.calendars = !showSectionHeader.calendars">Calendars <i class="bi-caret-right-fill"></i></a>
                    <TextEdit :text="store.calendar.title" @close="textEditCalendarNameClose"></TextEdit>
                    <SelectEdit :name-values="store.calendarYears.years" :selected-value="store.calendar.year" @close="selectEditCalendarYearClose"></SelectEdit>
                    <a class="static-row hover" v-tooltip="'View Calendar'" @click="$router.push({ name: 'calendar', params: { id: store.calendar.id } })">
                      <i class="bi-calendar2-month"></i>
                    </a>
                  </div>
                </div>
                <div class="section-buttons">                  
                  <layout-toggle :section="store.layout.calendar" @layoutChanged="(layout) => setLayout('calendar', layout)" />
                </div>
              </div>
            </div>
            </Transition> 

            <!-- Calendar Items -->
            <div
              class="image-layout image-layout-calendar"
              :class="[store.layout.calendar, showSectionHeader.calendars ? 'open' : 'closed']"
              v-if="store.calendar != null"
              @dragenter="($event) => dragenter($event, 'calendar')"
              @dragleave="($event) => dragleave($event, 'calendar')"
            >
              <div v-for="(item, index) in store.calendarImages" :key="item.position" class="item">
                <h6 class="grid-info">{{ item.positionName }}</h6>
                <div
                  class="thumbnail"
                  :class="{ 'duplicate-highlight': item.highlight, dragging: item.dragging, 'drag-enter': item.dragEnter, cover: item.position == 0 }"
                  :data-index="index"
                  :data-from="'calendarImages'"
                  :data-image-id="item.imageId"
                  @drop="dropCalendarItem"
                  @dragover="($event) => dragover($event, 'calendar', store.calendar)"
                  @dragstart="dragstart"
                  @dragend="dragend"
                  @dragenter="($event) => dragenterCalendarItem($event, item, store.calendar)"
                  @dragleave="($event) => dragleaveCalendarItem($event, item)"
                  draggable="true"
                >
                  <div :data-index="index" class="thumbnail-image" :style="'background-image: url(https://image.foundimagepress.com/' + item.artCode">
                    <div class="btn-icon-wrapper">
                      <button class="btn btn-secondary btn-icon-overlay" @click="modal.open('modalImage', item)">
                        <i class="bi-arrows-fullscreen"></i>
                      </button>
                      <button class="btn btn-secondary btn-icon-overlay" @click="removeCalendarImage(index)" v-tooltip="'Remove'">
                        <i class="bi-eraser-fill"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <ItemInfo :item="item" :show="store.layout.calendar == 'image-list'" @term-clicked="addSearchTerm"></ItemInfo>
              </div>
            </div>
          </template>
        </DragCol>
      </template>
    </DragRow>
  </div>

  <ModalConfirm
    id="modalDeleteCalendar"
    @close="deleteCalendar"
    :message="`Are you sure you want to permenantly delete calendar <strong>${store.calendar.title} ${store.calendar.year}</strong>?`"
  ></ModalConfirm>

  <ModalConfirm
    id="modalDeleteCollection"
    @close="deleteLightbox"
    :message="`Are you sure you want to permenantly delete collection <strong>${store.lightbox.title}</strong>?`"
  ></ModalConfirm>

  <ModalCreateLightbox id="modalCreateLightbox" @close="modalCreateLightboxClose"></ModalCreateLightbox>

  <ModalCreateCalendar id="modalCreateCalendar" @close="modalCreateCalendarClose"></ModalCreateCalendar>

  <ModalImage id="modalImage"></ModalImage>
</template>

<style lang="scss" scoped>

$drag-enter-border: 2px dashed darkgreen;
$fade-ms: 500ms;
$fade-effect: ease-in;
$slide-ms: 800ms;
$slide-effect: ease-out;

.absolute {
  position: absolute;
  left: 0;
  right: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity $fade-ms $fade-effect;
}

.fade-enter-from {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

.lightbox-drop-zone,
.scratch-drop-zone {
  &.drag-enter {
    * {
      pointer-events: none;
    }
  }
}

.section-header {
  display: flex;
  align-items: center;
  height: 36px;
  margin: 0.5rem 0.5rem 0 0.5rem;

  .hover {
    text-decoration: none;
    color:unset;

    &:hover {
      cursor: pointer;
      color: darkblue;
    }
  }

  select {
    min-width: 80px;
  }

  &.search {
    min-width: 664px;
    justify-content: space-between;
  }

  &.search.top {
    justify-content: flex-end;

    > div {
      flex-grow: 1;
    }
  }

  &.section-header-scratch {
    min-width: 150px;
  }

  &.section-header-lightbox {
    min-width: 460px;
    &.open {
    height: 72px;
    }
    &.closed {
      height: 36px;
    }
  }

  &.section-header-calendars {
    min-width: 460px;
  }

  &.section-header-calendar {
    min-width: 460px;
    &.open {
    height: 72px;
    }
    &.closed {
      height: 36px;
    }
  }

  > * {
    margin-right: 0.25rem;
  }

  .section-title {
    // font-size: 1.6rem;

    &.shared-lightbox {
      display: flex;
      flex-direction: column;
    }

    .controls {
      display: flex;
      align-items: center;
      > * {
        margin-right: 1rem;
      }
      * {
        white-space: nowrap;
      }
    }

    .shared-by {
      font-family: 'Cabin Condensed';
      font-size: 1.2rem;
      color: #666;

      strong {
        font-weight: 400;
        color: #111;
        font-size: 1.3rem;
      }

      i {
        margin-left: 0.5rem;
      }
    }
  }

  .section-buttons {
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;

    .top {
      display: flex;
      align-items: center;

      .input-group {
        min-width: 200px;
      }
      .form-switch {
        min-width: 140px;
      }
      .form-select {
        max-width: 80px;
      }
    }
  }

  .layout-toggle-wrapper {
    margin-left: auto;
    align-self: flex-end;
  }

  p {
    line-height: 1.2;
  }
}

.image-grid-large .thumbnail-image {
  height: 200px;
  width: 200px;
}

.image-grid-medium .thumbnail-image {
  height: 120px;
  width: 120px;
}

.image-grid-small .thumbnail-image {
  height: 70px;
  width: 70px;
}

.image-list .thumbnail-image {
  height: 80px;
  width: 80px;
}

.image-layout {
  position: absolute;
  top: 96px; // Position in relation to .section-header
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  overflow-y: scroll;
  background-color: #fafafa;
  padding: 0.5rem;
  border-top: 1px solid #999;

  &.image-layout-lightbox,
  &.image-layout-calendar {
    &.open {
      top: 300px;
      transition: top  $slide-ms $slide-effect;
    }
    &.closed {
      top: 48px;
      transition: top  $slide-ms $slide-effect;
    }
  }
}

.image-list {
  .item {
    display: flex;
  }

  .grid-info {
    display: none;
  }
}

.thumbnail {
  position: relative;
  background-color: #f0f0f0;
  margin: 0.25rem;
  padding: 0.25rem;
  text-align: end;
  border: 2px solid transparent;

  .thumbnail-image {
    pointer-events: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.drag-empty-target {
    background-color: transparent;
  }

  &.dragging:hover button {
    display: none;
  }

  &.drag-enter {
    border: $drag-enter-border;
  }

  &.cover {
    border: 1px solid darkgoldenrod;
  }

  &.duplicate-highlight {
    border: 2px solid rgba(100, 158, 124, 0.5);
    background-color: rgba(100, 158, 124, 0.2);
  }

  .btn-icon-wrapper {
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    display: flex;
    justify-content: flex-end;
    height: 24px;
    width: 100px;

    .btn-icon-overlay {
      padding: 0 0.125rem;
      font-size: 1rem;
      border-radius: 0.125rem;
      border-color: #fff;
      background-color: rgba(0, 0, 0, 0.3);
      pointer-events: all;
      display: none;
    }
  }
  &:hover {
    .btn-icon-overlay {
      display: block;
    }
  }
}

.image-layout.drag-enter {
  .thumbnail.drag-empty-target {
    border: $drag-enter-border;
    background-color: #f0f0f0;
  }
}

.collection-icons {
  margin: 0.25rem 0;
  display: flex;
  overflow-x: scroll;
  flex-shrink: 0;
  background-color: rgb(245, 216, 172); // rgb(244 209 156);

  .collection-icon {
    height: 148px; // Allow for long titles
    width: 120px;
    min-height: 148px;
    min-width: 120px;
    padding: 0.25rem;
    border: 1px solid transparent;
    margin: 0.25rem;
    cursor: pointer;

    * {
      pointer-events: none;
    }

    &:hover {
      background-color: rgb(252, 241, 224);
    }

    &.active {
      background-color: blanchedalmond;
    }

    &.drag-enter-collection {
      border: $drag-enter-border;
    }

    &.drag-enter-create {
      border: $drag-enter-border;
    }

    .title {
      font-family: 'Cabin Condensed';
      font-size: 0.9rem;
      line-height: 1.2;
      text-align: center;
    }
    .owner {
      font-family: 'Cabin Condensed';
      font-size: 0.8rem;
      line-height: 1.2;
      text-align: center;
      color: #777;
    }
  }
}

</style>
