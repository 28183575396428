<script setup lang="ts">
import { ICollection, Image } from '@/types/image';
import { ref, watch } from 'vue';
import { useCalendarsStore } from '@/stores/calendars';
import { useAuthStore } from '@/stores/auth';

const store = useCalendarsStore();
const auth = useAuthStore();

interface Props {
  collection?: ICollection
}
const props = defineProps<Props>();
const showCount = !!props.collection;
const images = ref(props.collection?.images.filter((c) => c.imageId > 0) || ([] as Image[]));

watch(
  () => props.collection?.images,
  (changedValue) => {
    images.value = changedValue?.filter((c) => c.imageId > 0) || ([] as Image[]);
  },
  { deep: true }
);

function Display(property: string) {
  var nv = store.permissions.find((p) => p.value == props.collection?.permission);
  if(nv == null){
    return '';
  }
  return nv[property];
}
</script>

<template>
  <div class="mini-images-wrapper">
    <div class="mini-images">
      <div
        v-if="images.length > 2"
        class="mini-image"
        style="top: 0; left: 46px; opacity: 0.7"
        :style="'background-image: url(https://image.foundimagepress.com/' + images[2].artCode"
      ></div>
      <div v-else class="mini-image-outline" style="top: 0; left: 56px; opacity: 0.55"></div>
      <div
        v-if="images.length > 1"
        class="mini-image"
        style="top: 4px; left: 23px; opacity: 0.85"
        :style="'background-image: url(https://image.foundimagepress.com/' + images[1].artCode"
      ></div>
      <div v-else class="mini-image-outline" style="top: 4px; left: 33px; opacity: 0.7"></div>
      <div
        v-if="images.length > 0"
        class="mini-image"
        style="top: -4px; left: 0; opacity: 1"
        :style="'background-image: url(https://image.foundimagepress.com/' + images[0].artCode"
      ></div>
      <div v-else class="mini-image-outline" style="top: -4px; left: 10px; opacity: 0.85"></div>
      <div class="badge status" v-if="collection?.permission > 0 && collection?.identityName != auth.account.username"><i :class="Display('icon')"></i></div>
      <div class="badge count" v-if="showCount">{{ images.length }}</div>
      <div class="badge count" v-else><i class="bi-plus-lg"></i></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mini-images {
  margin-top: 0.5rem;
  height: 68px;

  > * {
    position: absolute;
  }
}
.mini-image {
  height: 54px;
  width: 54px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.mini-image-outline {
  height: 54px;
  width: 34px;
  background-color: #ddd;
  border: 1px solid #666;
}
.badge {
  font-weight: 400;
  font-family: 'Cabin Condensed';
  border-radius: 0.25rem;

  &.status {
    bottom: 8px;
    left: 8px;
    background-color: #666;
  }

  &.count {
    bottom: 8px;
    right: 8px;
    background-color: rgb(226, 124, 0);
  }
}
</style>
