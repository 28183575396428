<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';

const store = useCalendarsStore();

interface Props {
  showMode: string; // active, inactive, demo-any, demo-all
}

const props = defineProps<Props>();
</script>

<template>
  <div class="any-all me-2" v-show="props.showMode == 'active'">
    <div class="btn-group btn-group-sm" role="group" aria-label="Any or all toggle">
      <input id="orientationV" class="btn-check" type="radio" name="orientation" :value="1" autocomplete="off" v-model="store.orientation" />
      <label class="btn btn-outline-secondary" for="orientationV"><i class="bi-image-alt" v-tooltip="'Horizontal Only'" ></i></label>
      <input id="orientationH" class="btn-check" type="radio" name="orientation" :value="2" autocomplete="off" v-model="store.orientation" />
      <label class="btn btn-outline-secondary" for="orientationH"><i class="bi-person-fill" v-tooltip="'Vertical Only'"></i></label>
      <input id="orientationA" class="btn-check" type="radio" name="orientation" :value="0" autocomplete="off" v-model="store.orientation" />
      <label class="btn btn-outline-secondary" for="orientationA"><i class="bi-files" v-tooltip="'Horizontal & Vertical'"></i></label>
    </div>
  </div>
  <div class="any-all me-2 faded" v-show="props.showMode == 'inactive'">
    <div class="btn-group btn-group-sm">
      <input id="any" class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary"><i class="bi-image-alt"></i></label>
      <input id="any" class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary"><i class="bi-person-fill"></i></label>
      <input id="all" class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary">ALL</label>
    </div>
  </div>
  <div class="any-all demo" v-show="props.showMode == 'demo-any'">
    <div class="btn-group btn-group-sm">
      <input class="btn-check" type="radio" checked />
      <label class="btn btn-outline-secondary">ANY</label>
      <input id="all" class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary">ALL</label>
    </div>
  </div>
  <div class="any-all demo" v-show="props.showMode == 'demo-all'">
    <div class="btn-group btn-group-sm">
      <input class="btn-check" type="radio" />
      <label class="btn btn-outline-secondary">ANY</label>
      <input id="all" class="btn-check" type="radio" checked />
      <label class="btn btn-outline-secondary">ALL</label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn {
  font-family: 'Cabin Condensed';
}

.faded {
  opacity: 0.3;
  pointer-events: none;
}

.demo .btn {
  font-size: 0.5rem;
  padding: 0.125rem 0.25rem;
}
</style>