<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';
const store = useCalendarsStore();
</script>

<template>
  <div class="search-summary me-2">
    <div v-if="store.searchImages.length === 0" class="me-3">No Found Images</div>
    <div v-else>
      <strong class="me-1">{{ store.search.imageCount }}</strong>
      <span> Found Images</span>
      <span class="hide-medium">, Showing Page</span>
      <button
        class="btn btn-outline-secondary btn-sm btn-icon ms-1 me-1"
        :class="{ disabled: store.search.page === 0 }"
        @click="store.getSearchImages(store.search.page - 1)"
        v-tooltip="'Previous Page'"
      >
        <i class="bi-caret-left-fill"></i>
      </button>
      <div>
        <strong>{{ store.search.page + 1 }}</strong>
        <span> of </span>
        <strong>{{ Math.ceil(store.search.imageCount / store.search.pageSize) }}</strong>
      </div>
      <button
        class="btn btn-outline-secondary btn-icon btn-sm ms-1 me-1"
        :class="{ disabled: store.search.imageCount <= (store.search.page + 1) * store.search.pageSize }"
        @click="store.getSearchImages(store.search.page + 1)"
        v-tooltip="'Next Page'"
      >
        <i class="bi-caret-right-fill"></i>
      </button>
      <span class="hide-medium me-1">Page Size</span>
      <select v-model="store.searchPageSize" class="form-select form-select-sm hide-small" v-tooltip="'Page Size'">
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-summary {
  width: 100%;

  > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  select {
    max-width: 74px;
  }
}

.btn-icon {
  border-color: transparent;

  &.disabled {
    border-color: transparent;
    opacity: 0.3;
  }
}
</style>
