import { defineStore } from 'pinia';
import axios from 'axios';
import type { ICalendarSvg2 } from '@/types/image';

const STATES = {
  INIT: 0,
  DONE: 1,
  WIP: 2,
  ERROR: 3,
};

const calendarApi = 'https://bikini-web-calendars.azurewebsites.net';
// const calendarApi = 'https://localhost:7241';

const designApi = 'https://design.foundimagepress.com';
// const designApi = 'https://localhost:5009';


export const useShowStore = defineStore('show', {
  state: () => ({
    calendarSvgs: [] as ICalendarSvg2[],
    dataState: STATES.INIT,
  }),
  actions: {
    // Get calendar SVG
    getCalendarsAllSvg() {
      this.dataState = STATES.WIP;
            return axios
        .get(`${calendarApi}/api/calendar/svg`)
        .then((res) => {
          this.dataState = STATES.DONE;
          console.log(res);
          this.calendarSvgs = res.data
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
  },
});
