import { defineStore } from 'pinia';

export const useToggleStore = defineStore('toggle', {
  state: () => ({
    isOpen: false
  }),

  actions: {
    toggle() {
      this.isOpen = !this.isOpen;
      return this.isOpen;
    }
  },
});
