<script setup lang="ts">
import { ref } from 'vue';
import { useCounterStore } from '@/stores/counter';
import { useModalStore } from '@/stores/modal';
import { useCalendarsStore } from '@/stores/calendars';
import ModalConfirm from '@/components/ModalConfirm.vue';

const counter = useCounterStore();
const store = useCalendarsStore();
const modal = useModalStore();


counter.increment();

let confirmation = ref(false);
</script>

<template>
  <div class="about mt-3">
    <h1>Prototypes</h1>

    <div class="counter mb-3">
      <h3>Counter: {{ counter.count }} (Double: {{ counter.doubleCount }})</h3>
      <p>Using store getters()</p>
      <button class="btn btn-outline-secondary m-1" @click="counter.decrement()">Decrement</button>
      <button class="btn btn-outline-secondary m-1" @click="counter.increment()">Increment</button>
    </div>

    <div class="modal-prototype mb-3">
      <h3>Confirm Modal</h3>
      <button class="btn btn-outline-secondary m-1" @click="($event) => modal.open('confirm33')">Confirm33</button>
      <button class="btn btn-outline-secondary m-1" @click="($event) => modal.open('confirm44')">Confirm44</button>

      <p>Confirmation: {{ confirmation }}</p>

      <ModalConfirm id="confirm33" @close="confirmation = $event"></ModalConfirm>
      <ModalConfirm id="confirm44" message="About to be sure?" :ok-button="{label: 'YES', color: 'outline-danger' }" @close="confirmation = $event"></ModalConfirm>

    </div>

    <div class="mb-3">
      <button v-tooltip="'Hello'">Hover</button>
    </div>
    
    <div class="mb-3">
      <h3>Shared Data</h3>
      <p>statuses {{ store.statuses }}</p>
      <p>permissions {{ store.permissions }}</p>
      <p>identityNames {{ store.identityNames }}</p>
      <p>calendarYears {{ store.calendarYears }}</p>
      <p>categories (count) {{ store.categories.length }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>

@media (min-width: 1024px) {
  .about {
    min-height: 100vh;
    margin: 1rem;
    display: flex;
    flex-direction: column;
  }
}
</style>
