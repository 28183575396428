<script setup lang="ts">
import {ref} from 'vue';
import { useModalStore } from '@/stores/modal';

const modal = useModalStore();
const emits = defineEmits(['close', 'ok']);

interface Props {
  id: string,
}
const props = defineProps<Props>();

function close() {
  modal.isOpen[props.id] = false;
  emits('close', false); 
  lightboxName.value = '';
}
function ok() {
  modal.isOpen[props.id] = false;
  emits('close', true, lightboxName.value);
  lightboxName.value = '';
}

let lightboxName = ref('');

</script>

<template>
  <div v-show="modal.isOpen[props.id]" class="modal-backdrop">
    <div class="dialog">
      <div class="card text-dark">
        <div class="card-header">
          <h5>New Collection</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
        </div>
        <div class="card-body">
          <div class="mb-3">
            <label class="form-label">Name your collection</label>
            <input class="form-control" v-model="lightboxName" />
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-sm btn-success ms-2" @click="ok">Create</button>
          <button class="btn btn-sm btn-primary ms-2" @click="close">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-backdrop {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.dialog {
  position: fixed;
  z-index: 2;
  top: 20%;
  left: 50%;
  width: 300px;
  margin-left: -150px;
  background-color: #666;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0;
  }
}

.card-footer {
  text-align: end;
}
</style>
