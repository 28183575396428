<script setup lang="ts">
import { useModalStore } from '@/stores/modal';

interface Props {
  id: string;
}

const props = defineProps<Props>();

const emits = defineEmits(['close']);
const modal = useModalStore();

function close() {
  modal.close(props.id);
  emits('close', false);
  console.log('modal', modal.data)
}
</script>

<template>
  <div v-show="modal.isOpen[id]" class="modal-backdrop" @click="close">
    <div class="dialog" :class="modal.data?.orientation?.toLowerCase()">
      <div class="card bg-light text-dark">
        <img :src="'https://image.foundimagepress.com/' + modal.data.artCode" />
        <div class="btn-close-wrapper">
          <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
        </div>
        <div class="card-body">
          <p v-show="modal.data.orientation ==''" class="text-danger">Orientation not set</p>
          <h5 class="card-title">{{ modal.data.caption }} {{ modal.data.orientation }}</h5>
          <span v-for="k in modal.data.keywords" class="me-2">{{ k }}</span>
          <div class="links">
            <a target="_blank" :href="'https://images.foundimagepress.com/Images/Edit/ArtCode/' + modal.data?.artCode">EDIT <i class="bi-box-arrow-up-right"></i></a>
            <a target="_blank" :href="'https://image.foundimagepress.com/' + modal.data?.artCode + '/preview'">PREVIEW <i class="bi-box-arrow-up-right"></i></a>
            <a target="_blank" :href="'https://image.foundimagepress.com/' + modal.data?.artCode + '/medium'">MEDIUM <i class="bi-box-arrow-up-right"></i></a>
            <a target="_blank" :href="'https://image.foundimagepress.com/' + modal.data?.artCode + '/high'">HIGH <i class="bi-box-arrow-up-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-backdrop {
  z-index: 3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.dialog {
  position: fixed;
  z-index: 2;
  left: 50%;
  background-color: #666;

  // Default if orientation not set
  top: 10%;
  width: 30%;
  margin-left: -15%;

  &.vertical {
    top: 5%;
    width: 40%;
    margin-left: -20%;
  }
  &.horizontal {
    top: 10%;
    width: 60%;
    margin-left: -30%;
  }
}

.btn-close-wrapper {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 22.5px; // match button
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #fff;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0;
  }
}

.card-body {
  .card-title {
    margin: 0;
  }

  span {
    white-space: nowrap;
  }

  .links {
    text-align: right;

    a {
      font-family: 'Cabin Condensed';
      font-size: 0.7rem;
      text-decoration: none;
      color: #666;
      padding: 0.25rem;
      background-color: #eee;
      margin-left: 0.5rem;
      border-radius: 0.125rem;

      &:hover {
        background-color: #ccc;
      }
    }
  }
}
</style>
