<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';
import AnyAll from './AnyAll.vue';

const store = useCalendarsStore();
</script>

<style scoped lang="scss">
.modal-backdrop {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.help {
  position: absolute;
  z-index: 2;
  left: 8px;
  top: 85px;
  bottom: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  overflow-y: scroll;

  &.query {
    min-width: 300px;

    .messsage {
      font-weight: bold;
    }
  }

  .pro {
    width: 460px;
  }

  .expert {
    width: 680px;

    .expert-columns {
      display: flex;
      align-items: flex-start;
    }
  }

  h6 {
    margin: 0.5rem 0 0 0;
  }
  p {
    margin-bottom: 1rem;
  }

  .example {
    width: 26%;
    font-family: 'Cabin Condensed';
  }

  .syntax,
  .example {
    font-family: 'Cabin Condensed';
    color: blue;
  }
}
</style>

<template>
  <div v-show="store.searchControls.helpOpen" class="modal-backdrop" @click="store.closeSearchControls"></div>

  <div class="help" v-show="store.searchControls.helpOpen">
    <div class="basic" v-show="store.searchMode == 'basic'">
      <h5>Basic Search</h5>
      <ul>
        <li>Enter one or more words.</li>
        <li>Not case sensitive</li>
        <li>
          Similar words are automatically matched e.g.
          <ul>
            <li><b>fire</b> also matches <b>fires</b>, <b>fired</b> and <b>firing</b></li>
            <li><b>woman</b> also matches <b>women</b></li>
            <li><b>cherry</b> also matches <b>cherries</b></li>
          </ul>
        </li>
      </ul>
      <table class="table table-sm table-borderless">
        <tbody>
          <th colspan="2"><h6>Syntax</h6></th>
          <tr>
            <td class="syntax">apple</td>
            <td class="description">match <b>apple</b></td>
          </tr>
          <tr>
            <td class="syntax">apple orange banana</td>
            <td class="description">match <b>apple</b> <b>orange</b> <b>banana</b></td>
          </tr>
          <tr>
            <td class="syntax"><AnyAll show-mode="demo-any"></AnyAll></td>
            <td class="description">match any of the words</td>
          </tr>
          <tr>
            <td class="syntax"><AnyAll show-mode="demo-all"></AnyAll></td>
            <td class="description">only match images that include all words</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pro" v-show="store.searchMode == 'pro'">
      <h5>Pro Search</h5>

      <table class="table table-sm table-borderless">
        <tbody>
          <th colspan="2"><h6>Syntax</h6></th>
          <tr>
            <td class="syntax">apple</td>
            <td class="description">match <b>apple</b>.</td>
          </tr>
          <tr>
            <td class="syntax">+apple</td>
            <td class="description">must match <b>apple</b> word irrespective of other terms.</td>
          </tr>
          <tr>
            <td class="syntax">-apple</td>
            <td class="description"><u>exclude</u> images that match <b>apple</b>.<br />See Gotchas below</td>
          </tr>
          <tr>
            <td class="syntax">+"exact phrase"</td>
            <td class="description">match this exact phrase</td>
          </tr>
          <tr>
            <td class="syntax">+'fragment'</td>
            <td class="description">match this word fragment</td>
          </tr>
          <tr>
            <th colspan="2"><h6>Examples</h6></th>
          </tr>
          <tr>
            <td class="example">
              <div><span>apple orange</span></div>
            </td>
            <td>Search for <b>apple</b> <u>or</u> <b>orange</b>.</td>
          </tr>
          <tr>
            <td class="example">
              <div><span>+apple +orange</span></div>
            </td>
            <td>Search for <b>apple</b> <u>and</u> <b>orange</b>.</td>
          </tr>
          <tr>
            <td class="example">
              <div><span>+fruit apple</span></div>
            </td>
            <td>Search for <b>fruit</b>, prioritize <b>apple</b>.</td>
          </tr>
          <tr>
            <td class="example">
              <div><span>+fruit apple orange</span></div>
            </td>
            <td>Search for <b>fruit</b>, prioritize <b>apple</b> <u>or</u> <b>orange</b>.</td>
          </tr>
          <tr>
            <td class="example">
              <div><span>+fruit -apple</span></div>
            </td>
            <td>Search for <b>fruit</b>, <u>exclude</u> <b>apple</b>.</td>
          </tr>
          <tr>
            <td class="example">
              <div><span>+beach +"La Jolla"</span></div>
            </td>
            <td>Search for <b>beach</b> <u>and</u> the exact phrase <b>"La Jolla"</b>.</td>
          </tr>
          <tr>
            <td class="example">
              <div><span>+'apple'</span></div>
            </td>
            <td>Search for any word that includes <b>apple</b><br />e.g. apple, apples, pineapple, applejack.</td>
          </tr>
          <tr>
            <td class="example">
              <div><span>+'apple' -apple -apples</span></div>
            </td>
            <td>Search for any word that includes <b>apple</b>, exclude <b>apple</b> and <b>apples</b><br />e.g. pineapple, applejack, grappled.</td>
          </tr>
          <tr>
            <th colspan="3"><h6>Gotchas!</h6></th>
          </tr>
          <tr>
            <td class="example">
              <div><span>-apple</span></div>
            </td>
            <td>Invalid.<br />Since almost all images <u>do not include</u> <b>apple</b> a huge amount of results would be returned.</td>
          </tr>
          <tr>
            <td class="example">
              <div><span>fruit -apple</span></div>
            </td>
            <td>Invalid.<br />Since almost all images <u>do not include</u> <b>apple</b>, and <b>fruit</b> is not required (+), a huge amount of results would be returned.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="expert" v-show="store.searchMode == 'expert'">
      <h5>Expert Search</h5>
      <p>Enter a valid <a href="https://lucene.apache.org/core/2_9_4/queryparsersyntax.html" target="_blank">Lucene Query</a>.</p>

      <div class="expert-columns">
        <table class="table table-sm table-borderless me-4">
          <tbody>
            <th colspan="2"><h6>Common Syntax</h6></th>
            <tr>
              <td class="syntax">cats OR dogs</td>
              <td class="description">match either</td>
            </tr>
            <tr>
              <td class="syntax">cats dogs</td>
              <td class="description">match either (OR is assumed)</td>
            </tr>
            <tr>
              <td class="syntax">cats AND dogs</td>
              <td class="description">match both</td>
            </tr>
            <tr>
              <td class="syntax">+cats +dogs</td>
              <td class="description">match both, alternative syntax</td>
            </tr>
            <tr>
              <td class="syntax">+cats dogs</td>
              <td class="description">match <b>cats</b> and favor results with <b>dogs</b></td>
            </tr>
            <tr>
              <td class="syntax">"dogs chasing cats"</td>
              <td class="description">match this exact phrase</td>
            </tr>
            <tr>
              <th colspan="2"><h6>Categories</h6></th>
            </tr>
            <tr>
              <td class="syntax">CategoryCode:AA</td>
              <td class="description">all <b>AA</b> images</td>
            </tr>
            <tr>
              <td class="syntax">search.in(CategoryCode,AA,AD)</td>
              <td class="description">all <b>AA</b> and <b>AD</b> images</td>
            </tr>
            <tr>
              <td class="syntax">search.in(CategoryCode,AA,AD) +art</td>
              <td class="description">match <b>art</b> in all <b>AA</b> and <b>AD</b> images</td>
            </tr>
            <tr>
              <th colspan="2"><h6>Using parentheses</h6></th>
            </tr>
            <tr>
              <td class="syntax">(cat OR feline) AND (dog OR puppy)</td>
              <td class="description">match at least one of <b>cat</b> or <b>feline</b> and at least one of <b>dog</b> or <b>puppy</b></td>
            </tr>
            <tr>
              <td class="syntax">+(cat|feline) +(dog|puppy)</td>
              <td class="description">alternative syntax</td>
            </tr>
            <tr>
              <th colspan="2"><h6>Weighting terms with ^</h6></th>
            </tr>
            <tr>
              <td class="syntax">fruit apple^2</td>
              <td class="description">match <b>fruit</b> and boost results that include <b>apple</b></td>
            </tr>
            <tr>
              <td class="syntax">fruit apple^2 orange^3</td>
              <td class="description">match <b>fruit</b> and boost results that include <b>apple</b> and particularly <b>orange</b></td>
            </tr>
          </tbody>
        </table>

        <table class="table table-sm table-borderless">
          <tbody>
            <tr>
              <th colspan="2"><h6>Wildcards * and ?</h6></th>
            </tr>
            <tr>
              <td class="syntax">cat?</td>
              <td class="description">words starting with <b>cat</b> plus a <u>single character</u>. e.g. cats, Cate</td>
            </tr>
            <tr>
              <td class="syntax">ca?t</td>
              <td class="description">words starting with <b>ca</b> plus a <u>single character</u>, ending with <b>t</b>. e.g. cart, cast</td>
            </tr>
            <tr>
              <td class="syntax">cat*</td>
              <td class="description">words starting with <b>cat</b> plus <u>zero or more</u> other characters. e.g. cat, cats, catalog, cathartic</td>
            </tr>
            <tr>
              <td class="syntax">ca*t</td>
              <td class="description">words starting with <b>ca</b> plus <u>zero or more</u> other characters, ending with <b>t</b>. e.g. cat, cart, carpet</td>
            </tr>
            <tr>
              <td class="syntax">?cat</td>
              <td class="description">Invalid<br />use regular expression fragment match below.</td>
            </tr>
            <tr>
              <td class="syntax">*cat</td>
              <td class="description">Invalid<br />use regular expression fragment match below.</td>
            </tr>
            <tr>
              <th colspan="2"><h6>Regular Expressions</h6></th>
            </tr>
            <tr>
              <td class="syntax">/REGEX/</td>
              <td class="description">match <a href="https://help.oncrawl.com/en/articles/1685982-lucene-regex-cheat-sheet" target="_blank">regular expression</a> <b>REGEX</b></td>
            </tr>
            <tr>
              <td class="syntax">/..ight/</td>
              <td class="description">match any two characters followed by <b>ight</b> e.g. flight, Wright, blight</td>
            </tr>
            <tr>
              <td class="syntax">/.*chest.*/</td>
              <td class="description">match word fragment <b>chest</b> e.g. Chest, Rochester, orchestra, chestnut</td>
            </tr>

            <tr>
              <th colspan="2"><h6>Fuzzy match with ~</h6></th>
            </tr>
            <tr>
              <td class="syntax">peat~</td>
              <td class="description">
                match similarly spelled words e.g. <b>peat</b>, <b>heat</b>, <b>pear</b>, <b>bear</b>. Many results may be returned but the closest matches will be listed first.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="artCodes" v-show="store.searchMode == 'artCodes'">
      <h5>Art Code Search</h5>
      <ul>
        <li>Enter one or more Art Codes</li>
        <li>Not case sensitive</li>
      </ul>
      <table class="table table-sm table-borderless">
        <tbody>
          <th colspan="2"><h6>Syntax</h6></th>
          <tr>
            <td class="syntax">AA-00001</td>
            <td class="description">match <b>AA-01</b></td>
          </tr>
          <tr>
            <td class="syntax">AA-1</td>
            <td class="description">match <b>AA-01</b></td>
          </tr>
          <tr>
            <td class="syntax">AA1</td>
            <td class="description">match <b>AA-01</b></td>
          </tr>
          <tr>
            <td class="syntax">aa1</td>
            <td class="description">match <b>AA-01</b></td>
          </tr>
          <tr>
            <td class="syntax">AA0000001</td>
            <td class="description">match <b>AA-01</b></td>
          </tr>
          <tr>
            <td class="syntax">AA1 AA2</td>
            <td class="description">match <b>AA-01</b> and <b>AA-02</b></td>
          </tr>
          <tr>
            <td class="syntax">AA1 AA2 AA3 cats</td>
            <td class="description">match <b>AA-01</b>, <b>AA-02</b> and <b>AA-03</b><br />Ignore <b>cats</b></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
