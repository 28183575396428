<script setup lang="ts">
import { RouterLink, RouterView } from "vue-router";
import SyncStatus from "@/components/SyncStatus.vue";
import { useAuthStore } from "./stores/auth";

const auth = useAuthStore();
</script>

<template>
  <header>
    <div>
      <nav v-if="auth.account?.name">
        <RouterLink to="/">Home</RouterLink>
        <RouterLink to="/search">Images</RouterLink>
        <RouterLink to="/calendars">Calendars</RouterLink>
        <RouterLink to="/fonts">Fonts</RouterLink>
        <RouterLink to="/show">Show</RouterLink>
        <RouterLink to="/about">About</RouterLink>
        <div class="ms-auto">
          <div v-if="auth.account?.name" class="welcome">{{ auth.account.name }}</div>
          <div v-else>
            <button class="btn btn-sm btn-outline-light" @click="auth.SignIn">Sign In</button>
          </div>
        </div>
        <div class="ms-2 nav-sync-status">
          <sync-status />
        </div>
      </nav>
      <nav v-else>
        <div class="ms-auto">
          <div v-if="auth.account?.name" class="welcome">{{ auth.account.name }}</div>
          <div v-else>
            <button class="btn btn-sm btn-outline-light" @click="auth.SignIn">Sign In</button>
          </div>
        </div>
        <div class="ms-2 nav-sync-status">
          <sync-status />
        </div>
      </nav>
    </div>
    <div class="line"></div>
  </header>

  <!-- Define key in order to refresh views on parameter change -->
  <RouterView :key="$route.fullPath" />

</template>

<style lang="scss" scoped>
header {
  line-height: 1.5;
  height: 50px;
  background-color: rgb(31, 19, 2);

  .line {
    height: 4px;
    border-top: 3px solid rgb(221, 119, 2);
    border-bottom: 1px solid #666;
  }
}

nav {
  display: flex;
  align-items: center;
  font-size: 1rem;

    a.router-link-exact-active {
    color: #ccc;
  }

  a.router-link-exact-active:hover {
    background-color: transparent;
  }

  a {
    display: inline-block;
    padding: 0 1rem;
    text-decoration: none;
    color: rgb(221, 119, 2);
    transition: 0.4s;
  }

  .nav-sync-status {
    padding: 0.25rem;
  }
  .welcome {
    color: #ccc;
  }
}
</style>
