import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';
import CalendarViewVue from '@/views/CalendarView.vue';
import CalendarsViewVue from '@/views/CalendarsView.vue';
import SearchViewVue from '@/views/SearchView.vue';
import LayoutViewVue from '@/views/LayoutView.vue';
import LightboxesViewVue from '@/views/LightboxesView.vue';
import FontsViewVue from '@/views/FontsView.vue';
import ShowViewVue from '@/views/Show.vue';
import ShowSingleVue from '@/views/ShowSingle.vue';

// https://davestewart.co.uk/blog/msal-vue/
// https://github.com/davestewart/msal-vue-demo/tree/main

function beforeEnter(to, from, next){
  const auth = useAuthStore(); // Must invoke within function because not available before.
  if(auth.account){
    return next();
  }
  auth.SignIn().then(()=>next());
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: AboutView,
      beforeEnter: beforeEnter
    },
    {
      path: '/search',
      name: 'search',
      component: SearchViewVue,
      beforeEnter: beforeEnter
    },
    {
      path: '/collections',
      name: 'collections',
      component: LightboxesViewVue,
      beforeEnter: beforeEnter
    },
    {
      path: '/calendars',
      name: 'calendars',
      component: CalendarsViewVue,
      beforeEnter: beforeEnter
    },
    {
      path: '/calendar/:id',
      name: 'calendar',
      component: CalendarViewVue,
      beforeEnter: beforeEnter
    },
    {
      path: '/layout',
      name: 'layout',
      component: LayoutViewVue,
      beforeEnter: beforeEnter
    },
    {
      path: '/fonts',
      name: 'fonts',
      component: FontsViewVue,
      beforeEnter: beforeEnter
    },
    {
      path: '/show',
      name: 'show',
      component: ShowViewVue,
      beforeEnter: beforeEnter
    },
    {
      path: '/showSingle/:id',
      name: 'showSingle',
      component: ShowSingleVue,
      beforeEnter: beforeEnter
    },
  ]
})

export default router
