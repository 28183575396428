<script setup lang="ts">
import { ref, watch } from 'vue';
import type { ICollection } from '@/types/image';
import { useCalendarsStore } from '@/stores/calendars';
import { useModalStore } from '@/stores/modal';
import { useTableStore } from '@/stores/table';
import SortButton from '@/components/SortButton.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

const modal = useModalStore();
const store = useCalendarsStore();
const table = useTableStore();

let deleteCalendarId = 0;
let newCalendar = ref({} as ICollection);
let searchText = ref('');
let identityName = ref('');

watch(
  () => identityName,
  async () => {
    await store.loadLightboxes(identityName.value);
  },
  { deep: true }
);

function showNewCalendar() {
  // store.resetCalendar(newCalendar.value);
  // newCalendar.value.edit = true;
}

function saveNewCalendar(c: ICollection, next: boolean) {
  // store.createCalendar(c);
  // store.resetCalendar(newCalendar.value);
  // newCalendar.value.edit = next;
}

function saveLightbox(c: ICollection) {
  store.saveLightbox(c);
  c.edit = false;
}

function saveLightboxCancel(c: ICollection) {
  // if (c.id > 0) {
  //   store.refreshCalendar(c);
  // } else {
  // }
}

function editCalendar(c: ICollection) {
  c.edit = !c.edit;
}

async function cancelAll() {
  // await store.loadCalendars();
}

function deleteCalendar(c: ICollection) {
  // deleteCalendarId = c.id;
  // modal.open('confirmDeleteCalendar');
}

function anyEdit() {
  return store.lightboxes.some((c) => c.edit);
}

function modalConfirmClose(confirm: boolean) {
  // if (confirm) {
  //   store.deleteCalendar(deleteCalendarId);
  // }
}

function collectionsFiltered() {
  return store.lightboxes.filter((c) => c.title.toLowerCase().includes(searchText.value.toLowerCase()) || c.year.toString().includes(searchText.value));
}
</script>

<template>
  <div class="container">
    <div class="calendars row mt-3">
      <div class="col">
        <h2>Collections</h2>
      </div>
      <div class="col-3">
        <th>
          <select v-model="identityName" class="form-select form-select-sm">
                    <option>nick@foundimage.com</option>
                    <option>cate@foundimage.com</option>
                    <option>benjamin@foundimage.com</option>
                    <option>sally@foundimage.com</option>
                  </select>
              </th>
      </div>
      <div class="col-4">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1"><i class="bi-search"></i></span>
          <input type="text" v-model="searchText" class="form-control" placeholder="Year or Title" aria-label="Search" aria-describedby="basic-addon1" />
          <button @click="searchText = ''" class="btn btn-secondary" id="basic-addon1"><i class="bi-x"></i></button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-light">
          <!-- HEAD -->
          <thead>
            <tr>
              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Title"
                  property="title"
                ></sort-button>
              </th>

              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Owner"
                  property="identityName"
                ></sort-button>
              </th>

              <th>
                <sort-button
                  :current-sort-descending="table.sortDescending"
                  :current-sort-column="table.sortColumn"
                  @sort="store.sortCalendars"
                  title="Permission"
                  property="permission"
                ></sort-button>
              </th>

              <th class="text-end">
                <button class="btn btn-sm btn-outline-secondary cancel-all" :class="{ show: anyEdit() }" @click="cancelAll()"><i class="bi-x"></i></button>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="c in collectionsFiltered()" :key="c.id" :value="c.id">
              <!-- VIEW ROW -->
              <tr v-if="!c.edit" class="static-row">
                <td>{{ c.title }}</td>
                <td>
                  <small>{{ c.identityName }}</small>
                </td>
                <td>{{ c.permissionName }}</td>
                <td class="text-end edit-controls">
                  <button class="btn btn-sm btn-outline-secondary me-1" @click="editCalendar(c)"><i class="bi-pencil-fill"></i></button>
                  <button class="btn btn-sm btn-outline-secondary me-1" @click="$router.push({ name: 'calendar', params: { id: c.id } })"><i class="bi-images"></i></button>
                  <button class="btn btn-sm btn-outline-secondary" @click="deleteCalendar(c)"><i class="bi-trash-fill"></i></button>
                </td>
              </tr>
              <!-- EDIT ROW -->
              <tr v-if="c.edit">
                <td>
                  <input id="title" v-model="c.title" class="form-control form-control-sm" />
                </td>
                <td>
                  <small>{{ c.identityName }}</small>
                </td>
                <td>
                  <select v-model="c.permission" class="form-select form-select-sm">
                    <option v-for="p in store.permissions" :value="p.value">{{ p.name }}</option>                   
                  </select>
                </td>
                <td class="text-end">
                  <button class="btn btn-sm btn-outline-success me-1" @click="saveLightbox(c)"><i class="bi-check"></i></button>
                  <button class="btn btn-sm btn-outline-secondary me-1" @click="saveLightboxCancel(c)"><i class="bi-x"></i></button>
                </td>
              </tr>
            </template>

            <!-- NEW ROW -->
            <tr v-if="newCalendar.edit">
              <td>
                <input id="title" v-model="newCalendar.title" class="form-control form-control-sm" />
              </td>
              <td></td>
              <td></td>
              <td class="text-end edit-controls">
                <button class="btn btn-sm btn-outline-success me-1" @click="saveNewCalendar(newCalendar, true)"><i class="bi-arrow-return-left"></i></button>
                <button class="btn btn-sm btn-outline-success me-1" @click="saveNewCalendar(newCalendar, false)"><i class="bi-check-lg"></i></button>
                <button class="btn btn-sm btn-outline-secondary me-1" @click="newCalendar.edit = false"><i class="bi-x"></i></button>
              </td>
            </tr>

            <!-- NEW BUTTON -->
            <tr v-else class="add-row" @click="showNewCalendar">
              <td colspan="7">
                <div class="active">
                  <button class="btn btn-sm btn-outline-secondary"><i class="bi-plus"></i></button>
                </div>
                <div class="inactive">
                  <button class="btn btn-sm btn-success me-3"><i class="bi-plus"></i></button>
                  <span>Add new calendar</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <modal-confirm id="confirmDeleteCalendar" @close="modalConfirmClose" :message="'Are you sure you want to permenantly delete this calendar?'"></modal-confirm>
  </div>
</template>

<style lang="scss" scoped>
.cancel-all {
  display: none;

  &.show {
    display: inline-block;
  }
}

.edit-controls {
  min-width: 130px;
}

.static-row {
  .edit-controls {
    button {
      opacity: 0;
    }
  }

  &:hover {
    .edit-controls {
      button {
        opacity: 1;
      }
    }
  }
}

.add-row td {
  opacity: 1;

  .active {
    display: block;
  }
  .inactive {
    display: none;
  }

  &:hover {
    background-color: #fff;
    opacity: 1;
    cursor: pointer;

    .active {
      display: none;
    }
    .inactive {
      display: block;
    }
  }
}
</style>
