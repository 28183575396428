<script lang="ts" setup>
import { INameValue } from '@/types/image';
import { nextTick } from 'process';
import { ref, watch } from 'vue';

interface Props {
  nameValues: INameValue[];
  selectedValue?: any; // any is a bit loose, requires correct implementation
  id?: string; // optional reference that is passed back on close
  alwaysEditable?: boolean; // Always show select
  placeholder?: string; // select first option - resets to this after selection
}
const props = defineProps<Props>();
const emits = defineEmits(['close']);

const newValue = ref(props.selectedValue);

watch(
  () => props.selectedValue,
  (changedValue) => {
    newValue.value = changedValue;
  }
);

const selectControl = ref(null);
const edit = ref(props.alwaysEditable);

async function activateEdit(e: any) {
  edit.value = true;
  await nextTick(() => {
  selectControl.value.focus()
});
}

function save() {
  edit.value = props.alwaysEditable;
  if(props.placeholder){
    selectControl.value.selectedIndex = 0;
  }
  emits('close', newValue, props.id);
}

function Display(property: string) {
  var nv = props.nameValues.find((p) => p.value == props.selectedValue);
  if (nv == null) {
    return 'Select...';
  }
  return nv[property];
}
</script>

<template>
  <div>
    <div class="editable" :class="{ 'hide-input': edit }" v-tooltip="'Edit'" @click="activateEdit" :style="Display('style')">{{ Display('name') }} <i :class="Display('icon')"></i></div>
    <div :class="{ 'hide-input': !edit }">
      <select ref="selectControl" v-model="newValue" class="form-select form-select-sm" @change="save" @blur="edit = props.alwaysEditable">
        <option v-if="props.placeholder" value="0" disabled>{{ props.placeholder }}</option>
        <option :style="p.style" v-for="p in props.nameValues" :value="p.value">{{ p.name }}</option>
      </select>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hide-input {
  display: none;
}

.editable {
  font-family: 'Cabin Condensed';

  &.text-muted {
    opacity: 0.4;
  }

  background-color: #fff; // #f5d9b0;
  border: 1px solid rgb(199, 186, 163);
  padding: 0.125rem 0.5rem;
  border-radius: 0.125rem;

  &:hover {
    border-color: rgb(226, 124, 0);
  }
}
</style>
