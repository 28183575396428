<script setup lang="ts">
import { useCalendarsStore } from "@/stores/calendars";

const store = useCalendarsStore();

</script>

<template>
  <span class="status done" v-if="store.dataState == 1" > <i class="bi-arrow-repeat"></i></span>
  <span class="status wip" v-else-if="store.dataState == 2"  ><i class="bi-arrow-repeat"></i></span>
  <span class="status error" v-else-if="store.dataState == 3"><i class="bi-arrow-repeat"></i></span>
  <span class="status " v-else><i class="bi-arrow-repeat"></i></span>
</template>

<style lang="scss" scoped>

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flash {
  100% {
    opacity: 0;
  }
}

.status {
  display: none;
  font-size: 31px;
  line-height: 29px;
  border-radius: 0.25rem;
  padding: 0.25rem;
  color: white;
  display: inline-block;

  &.wip {
    background-color: rgb(49, 163, 184);

    i:before {
    animation: spin 1s linear infinite;
    }
  }

  &.error {    
    background-color: rgb(197, 25, 25);
    animation: flash 1s linear infinite;
  }

  &.done {
    background-color: darkgreen;
  }
}

</style>
