<script setup lang="ts">
import { ref, watch } from 'vue';

const emits = defineEmits(['layoutChanged']);
const layout = ref('classifications');

watch(
  () => layout.value,
  () => {
    emits('layoutChanged', layout.value);
  }
);
</script>

<template>
  <div class="btn-group" role="group" aria-label="Basic example">
    <button :class="[layout == 'classifications' ? 'btn-secondary' : 'btn-outline-secondary']" class="btn btn-sm" @click="layout = 'classifications'">
      <i class="bi-layout-three-columns"></i>
    </button>
    <button :class="[layout == 'all' ? 'btn-secondary' : 'btn-outline-secondary']" class="btn btn-sm" @click="layout = 'all'"><i class="bi-list"></i></button>
  </div>
</template>

<style lang="scss" scoped></style>
