import { defineStore } from 'pinia';
import axios from 'axios';
import type { Image, ICollection, ICategory, ICalendarYears, INameValue, ISearchRequest, ICalendarImage, ICalendar, ILayoutItem } from '@/types/image';
import { useAuthStore } from './auth';
import { useTableStore } from './table';

const STATES = {
  INIT: 0,
  DONE: 1,
  WIP: 2,
  ERROR: 3,
};

const calendarApi = 'https://bikini-web-calendars.azurewebsites.net';
// const calendarApi = 'https://localhost:7241';

const designApi = 'https://design.foundimagepress.com';
// const designApi = 'https://localhost:5009';

let typingTimer: any;

function compareByProp(a: object, b: object, prop: string, descending: boolean = false) {
  const sortA = typeof a[prop] == 'string' ? a[prop].toLowerCase() : a[prop];
  const sortB = typeof b[prop] == 'string' ? b[prop].toLowerCase() : b[prop];

  if (sortA < sortB) {
    return descending ? 1 : -1;
  }
  if (sortA > sortB) {
    return descending ? -1 : 1;
  }
  return 0;
}

export const useCalendarsStore = defineStore('calendars', {
  state: () => ({
    layout: {
      lightbox: 'image-grid-medium',
      scratch: 'image-grid-medium',
      calendar: 'image-grid-medium',
      search: 'image-grid-medium',
    },

    searchControls: {
      helpOpen: false,
      queryOpen: false,
      categoriesOpen: false,
    },

    searchPanelSize: 'large' as string, // small

    categorySortProperty: 'name' as string,
    categoryLayoutProperty: 'classifications' as string,
    categoryVendorId: 1 as number,
    categories: [] as ICategory[],
    categoryId: 0 as number,
    newCalendarName: '' as string,

    lightboxes: [] as ICollection[],
    lightbox: {} as ICollection,
    lightboxImages: [] as Image[],

    newLightboxName: '' as string,

    calendars: [] as ICollection[],
    calendar: {} as ICollection,
    calendarImages: [] as Image[],
    calendarYears: {} as ICalendarYears,
    calendarWithImageDuplicates: {} as ICalendar,

    scratchImages: [] as Image[],

    search: {} as ICollection,
    searchImages: [] as Image[],

    searchText: '' as string,
    categoryFilter: '' as string,
    searchPageSize: 50 as number,
    anyAll: 'all' as string,
    orientation: 0 as number,
    searchMode: 'basic' as string, // basic, pro, expert
    searchIsValid: true as boolean,
    searchQuery: '' as string,

    identityNames: [] as string[],
    statuses: [] as INameValue[],
    permissions: [] as INameValue[],
    fonts: [] as INameValue[],
    layoutItemTypes: {} as any,
    vendors: [] as INameValue[],
    customLayoutItems: [{name: 'Price', value: 1}, {name: 'Logo', value: 2}, {name: 'Barcode', value: 3}] as INameValue[],

    dataState: STATES.INIT,
  }),
  actions: {
    async loadSharedData() {
      this.dataState = STATES.WIP;
      return axios
        .get(calendarApi + '/api/utility')
        .then((res) => {
          this.calendarYears = res.data.calendarYears;
          this.categories = res.data.categories;
          this.identityNames = res.data.identityNames;
          this.statuses = res.data.statuses;
          this.permissions = res.data.permissions;
          this.fonts = res.data.fonts;
          this.layoutItemTypes = res.data.layoutItemTypes;
          this.vendors = res.data.vendors;
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    toggleSearchControl(control) {
      var open = this.searchControls[control];
      this.closeSearchControls();
      this.searchControls[control] = !open;
      return this.searchControls[control];
    },
    closeSearchControls() {
      for (let [key, value] of Object.entries(this.searchControls)) {
        this.searchControls[key] = false;
      }
    },
    searchNow() {
      clearTimeout(typingTimer);
      if (this.searchText.length > 0 || this.selectedCategories().length > 0) {
        typingTimer = setTimeout(this.getSearchImages, 500);
      } else {
        this.searchImages = [];
        this.search.searchQuery = '';
        this.search.searchFilter = '';
      }
    },
    getSearchImages(page?: number) {
      this.dataState = STATES.WIP;
      page = page || 0;
      let selectedCategories = this.categories.filter((c: ICategory) => c.isSelected).map((c: ICategory) => c.code);
      let searchText = this.searchText || '__';

      const searchRequest = {
        searchModeString: this.searchMode,
        pageSize: this.searchPageSize,
        page: page,
        anyAll: this.anyAll,
        orientation: this.orientation,
        searchText: searchText,
        categoryCodes: selectedCategories,
      } as ISearchRequest;

      axios
        .post(calendarApi + '/api/search', searchRequest)
        .then((res) => {
          console.log(res.data);

          this.search = res.data;
          this.searchImages = res.data.images;
          this.searchIsValid = res.data.isValid;
          this.searchQuery = res.data.searchQuery;
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log('search error', e);
          this.dataState = STATES.ERROR;
        });
    },
    getLocalStorage() {
      const scratch = localStorage.getItem('scratch');
      if (scratch != null) this.scratchImages = JSON.parse(scratch);
    },
    saveLocalStorage() {
      localStorage.setItem('scratch', JSON.stringify(this.scratchImages));
    },
    clearStorage() {
      localStorage.removeItem('scratch');
    },
    createCalendar(calendar: ICollection, addImage?: Image) {
      this.dataState = STATES.WIP;
      const auth = useAuthStore();
      const addImageId = addImage?.imageId || 0;
      calendar.identityName = auth.account?.username || 'Unknown';
      axios
        .post(calendarApi + '/api/calendar/create/' + addImageId, calendar)
        .then((res) => {
          console.log(res);
          this.calendar = res.data;
          this.calendarImages = this.calendar.images;
          this.calendars.push(this.calendar);
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    async createCalendarForUser() {
      this.dataState = STATES.WIP;
      const auth = useAuthStore();
      var identityName = auth.account?.username || 'Unknown';
      return axios
        .post(calendarApi + '/api/calendar/createForUser/' + identityName)
        .then((res) => {
          console.log(res);
          this.calendar = res.data;
          this.calendarImages = this.calendar.images;
          this.calendars.push(this.calendar);
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    saveCalendar(calendar: ICollection) {
      this.dataState = STATES.WIP;
      const auth = useAuthStore();
      calendar.identityName = auth.account?.username || 'Unknown';
      axios
        .put(calendarApi + '/api/calendar', calendar)
        .then((res) => {
          console.log(res);
          this.calendar = res.data;
          this.calendarImages = this.calendar.images;
          this.calendars = this.calendars.map((c) => (c.id == this.calendar.id ? this.calendar : c));
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    saveCalendarWithDuplicates(calendar: ICalendar) {
      this.dataState = STATES.WIP;
      return axios
        .put(calendarApi + '/api/calendar/full', calendar)
        .then((res) => {
          this.calendar = res.data;
          this.calendarImages = this.calendar.images;
          this.calendars = this.calendars.map((c) => (c.id == this.calendar.id ? this.calendar : c));
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    saveCalendarLayoutItem(layoutItem: ILayoutItem) {
      this.dataState = STATES.WIP;
      const calendarId = this.calendarWithImageDuplicates.id;
      return axios
        .put(calendarApi + '/api/calendar/layoutItem', layoutItem)
        .then((res) => {
          this.dataState = STATES.DONE;
          this.loadCalendarWithImageDuplicates(calendarId);
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    deleteCalendarLayoutItem(layoutItemId: number) {
      this.dataState = STATES.WIP;
      const calendarId = this.calendarWithImageDuplicates.id;
      return axios
        .delete(calendarApi + '/api/calendar/layoutItem/' + layoutItemId)
        .then((res) => {
          this.dataState = STATES.DONE;
          this.loadCalendarWithImageDuplicates(calendarId);
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    addCalendarLayoutItem(layoutItemName: string) {
      this.dataState = STATES.WIP;
      const calendarId = this.calendarWithImageDuplicates.id;
      return axios
        .post(calendarApi + '/api/calendar/layoutItem/' + calendarId + '/' + layoutItemName)
        .then((res) => {
          this.dataState = STATES.DONE;
          this.loadCalendarWithImageDuplicates(calendarId);
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    clearSearch() {
      this.search = {};
      this.searchImages = [];
      this.categories.forEach((c) => {
        c.isSelected = false;
      });
      this.searchText = '';
    },
    updateCalendarImages(images: Image[]) {
      this.dataState = STATES.WIP;
      this.calendar.images = images;
      axios
        .post(calendarApi + '/api/calendar', this.calendar)
        .then((res) => {
          console.log(res);
          this.calendar = res.data;
          this.calendarImages = this.calendar.images;
          this.calendars = this.calendars.map((c) => (c.id == this.calendar.id ? this.calendar : c));
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    async loadCalendars(loadFirstCalendar: boolean = false) {
      this.dataState = STATES.WIP;
      const auth = useAuthStore();
      return axios
        .get<ICollection[]>(calendarApi + '/api/calendar/' + auth.account.username)
        .then((res) => {
          this.calendars = res.data;
          if (loadFirstCalendar && this.calendars.length > 0) this.loadCalendar(this.calendars[0].id); // Initialize with first calendar
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    resetCalendar(calendar: ICollection) {
      calendar.title = '';
      calendar.status = 1;
      calendar.font = '';
      calendar.year = this.calendarYears.current;
      calendar.notes = '';
    },
    refreshCalendar(calendar: ICollection) {
      this.dataState = STATES.WIP;
      axios
        .get<ICollection>(calendarApi + '/api/calendar/' + calendar.id)
        .then((res) => {
          calendar = res.data;
          this.calendars = this.calendars.map((c) => (c.id == calendar.id ? calendar : c));
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    // Load calendar for images view
    loadCalendar(calendarId: number) {
      this.dataState = STATES.WIP;
      axios
        .get<ICollection>(calendarApi + '/api/calendar/' + calendarId)
        .then((res) => {
          this.calendar = res.data;
          this.calendarImages = this.calendar.images;
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    // Load calendar for calendar view
    async loadCalendarWithImageDuplicates(calendarId: number) {
      this.dataState = STATES.WIP;
      return axios
        .get<ICalendar>(calendarApi + '/api/calendar/full/' + calendarId)
        .then((res) => {
          this.calendarWithImageDuplicates = res.data;
          this.dataState = STATES.DONE;
          console.log(this.calendarWithImageDuplicates);
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    // Get calendar SVG
    getCalendarSvg(calendarId: number, layout: string) {
      this.dataState = STATES.WIP;
      let productLayoutId = 0;

      switch (layout) {
        case 'cover':
          productLayoutId = 27;
          break;
        case 'back':
          productLayoutId = 28;
          break;
        case 'months':
          productLayoutId = 29;
          break;
      }

      return axios
        .get(`${designApi}/api/calendarapi?calendarId=${calendarId}&productLayoutId=${productLayoutId}`)
        .then((res) => {
          this.dataState = STATES.DONE;
          // console.log(res);
          return res.data;
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    getCalendarPdf(calendarId: number) {
      this.dataState = STATES.WIP;
      return axios
        .get(`${designApi}/api/calendarapi/pdf?calendarId=${calendarId}`)
        .then((res) => {
          this.dataState = STATES.DONE;
          // console.log(res);
          return res.data;
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    createProduct(calendarId: number) {
      this.dataState = STATES.WIP;
      return axios
        .put(`${calendarApi}/api/calendar/createProduct/${calendarId}`)
        .then((res) => {
          this.dataState = STATES.DONE;
          // console.log(res.data);
          this.calendarWithImageDuplicates = res.data;
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    deleteCalendar(calendarId: number) {
      this.dataState = STATES.WIP;
      axios
        .delete<boolean>(calendarApi + '/api/calendar/' + calendarId)
        .then(async (res) => {
          if (res.data) {
            const auth = useAuthStore();
            await this.loadCalendars(auth.account.username, true);
            this.dataState = STATES.DONE;
          } else {
            this.dataState = STATES.ERROR;
          }
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    async loadLightboxes(identityName: string, loadFirstLightbox: boolean = false) {
      this.dataState = STATES.WIP;
      try {
        const res = await axios.get<ICollection[]>(calendarApi + '/api/lightbox/' + identityName);
        this.lightboxes = res.data;
        if (loadFirstLightbox && this.lightboxes.length > 0) this.loadLightbox(this.lightboxes[0].id);
        this.dataState = STATES.DONE;
      } catch (e) {
        this.dataState = STATES.ERROR;
      }
    },
    loadLightbox(lightboxId: number) {
      this.dataState = STATES.WIP;
      axios
        .get<ICollection>(calendarApi + '/api/lightbox/' + lightboxId)
        .then((res) => {
          this.lightbox = res.data;
          this.lightboxImages = this.lightbox.images;
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    async createLightbox(lightboxName: string, addImage?: Image) {
      this.dataState = STATES.WIP;
      const auth = useAuthStore();
      const addImageId = addImage?.imageId || 0;
      return axios
        .get<ICollection>(calendarApi + '/api/lightbox/create/' + auth.account?.username + '/' + lightboxName + '/' + addImageId)
        .then(async (res) => {
          this.lightbox = res.data;
          this.lightboxImages = this.lightbox.images;

          // temp (why temp?)
          const auth = useAuthStore();
          await this.loadLightboxes(auth.account.username);
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    saveLightbox(lightbox: ICollection) {
      this.dataState = STATES.WIP;

      const auth = useAuthStore();
      lightbox.identityName = auth.account?.username || '';

      axios
        .put(calendarApi + '/api/lightbox', lightbox)
        .then((res) => {
          console.log(res);
          this.lightbox = res.data;
          this.lightboxes = this.lightboxes.map((lb) => (lb.id == this.lightbox.id ? this.lightbox : lb));
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          console.log(e);
          this.dataState = STATES.ERROR;
        });
    },
    async deleteLightbox(lightboxId: number) {
      this.dataState = STATES.WIP;
      return axios
        .delete<boolean>(calendarApi + '/api/lightbox/' + lightboxId)
        .then(async (res) => {
          if (res.data) {
            // temp
            const auth = useAuthStore();

            await this.loadLightboxes(auth.account.username, true);
            this.dataState = STATES.DONE;
          } else {
            this.dataState = STATES.ERROR;
          }
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    addLightboxImage(image: Image, lightboxId: number) {
      this.dataState = STATES.WIP;
      axios
        .put<ICollection>(calendarApi + '/api/lightbox/image/' + lightboxId, image)
        .then((res) => {
          this.lightbox = res.data;
          this.lightboxImages = this.lightbox.images;
          var index = this.lightboxes.findIndex((lb) => lb.id == this.lightbox.id);
          this.lightboxes[index] = this.lightbox;
          this.dataState = STATES.DONE;
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    removeLightboxImage(image: Image, lightboxId: number) {
      this.dataState = STATES.WIP;
      axios
        .delete<boolean>(calendarApi + '/api/lightbox/image/' + lightboxId + '/' + image.imageId)
        .then((res) => {
          if (res.data) {
            this.lightbox = res.data;
            this.lightboxImages = this.lightbox.images;
            var index = this.lightboxes.findIndex((lb) => lb.id == this.lightbox.id);
            this.lightboxes[index] = this.lightbox;
            this.dataState = STATES.DONE;
          } else {
            this.dataState = STATES.ERROR;
          }
        })
        .catch((e) => {
          this.dataState = STATES.ERROR;
        });
    },
    addScratch(image: Image) {
      if (!image || image.imageId == 0) return;

      this.scratchImages = this.scratchImages.filter((i) => i.imageId != image.imageId);
      this.scratchImages.push({
        id: 0,
        imageId: image.imageId,
        artCode: image.artCode,
        caption: image.caption,
        captionWords: image.captionWords,
        keywords: image.keywords,
        orientation: image.orientation,
        position: 0,
        positionName: '',
      } as Image);

      this.saveLocalStorage();
    },
    removeScratch(srcImage: Image) {
      this.scratchImages = this.scratchImages.filter((img) => img.imageId != srcImage.imageId);
      this.saveLocalStorage();
    },
    clearScratch() {
      this.scratchImages = [];
      this.clearStorage();
    },
    sortCategories() {
      this.categories.sort((a, b) => compareByProp(a, b, this.categorySortProperty));
    },
    selectedCategories() {
      return this.categories.filter((c) => c.isSelected);
    },
    clearCategories(e: any) {
      e.preventDefault();
      e.stopPropagation();
      this.categories.forEach((c) => (c.isSelected = false));
      this.searchNow();
    },
    sortCalendars(property: string) {
      const table = useTableStore();

      if (table.sortColumn == property) {
        table.sortDescending = !table.sortDescending;
      } else {
        table.sortColumn = property;
        table.sortDescending = false;
      }

      this.calendars.sort((a: object, b: object) => compareByProp(a, b, table.sortColumn, table.sortDescending));
    },
    setSwapCalendarImage(srcImage: Image, destImage: Image, swap?: boolean) {
      if (swap) {
        const tempArtCode = destImage.artCode;
        const tempImageId = destImage.imageId;
        const tempCaption = destImage.caption;
        const tempCaptionWords = destImage.captionWords;
        const tempKeywords = destImage.keywords;
        const tempOrientation = destImage.orientation;
        destImage.artCode = srcImage.artCode;
        destImage.imageId = srcImage.imageId;
        destImage.caption = srcImage.caption;
        destImage.captionWords = srcImage.captionWords;
        destImage.keywords = srcImage.keywords;
        destImage.orientation = srcImage.orientation;
        srcImage.artCode = tempArtCode;
        srcImage.imageId = tempImageId;
        srcImage.caption = tempCaption;
        srcImage.captionWords = tempCaptionWords;
        srcImage.keywords = tempKeywords;
        srcImage.orientation = tempOrientation;
      } else {
        destImage.artCode = srcImage.artCode;
        destImage.imageId = srcImage.imageId;
        destImage.caption = srcImage.caption;
        destImage.captionWords = srcImage.captionWords;
        destImage.keywords = srcImage.keywords;
        destImage.orientation = srcImage.orientation;
      }
    },
    setSwapCalendarDuplicateImage(srcImage: ICalendarImage, destImage: ICalendarImage, swap?: boolean) {
      if (swap) {
        const tempArtCode = destImage.artCode;
        const tempImageId = destImage.imageId;
        const tempCaption = destImage.caption;
        const tempCaptionWords = destImage.captionWords;
        const tempKeywords = destImage.keywords;
        const tempOrientation = destImage.orientation;
        destImage.artCode = srcImage.artCode;
        destImage.imageId = srcImage.imageId;
        destImage.caption = srcImage.caption;
        destImage.captionWords = srcImage.captionWords;
        destImage.keywords = srcImage.keywords;
        destImage.orientation = srcImage.orientation;
        srcImage.artCode = tempArtCode;
        srcImage.imageId = tempImageId;
        srcImage.caption = tempCaption;
        srcImage.captionWords = tempCaptionWords;
        srcImage.keywords = tempKeywords;
        srcImage.orientation = tempOrientation;
      } else {
        destImage.artCode = srcImage.artCode;
        destImage.imageId = srcImage.imageId;
        destImage.caption = srcImage.caption;
        destImage.captionWords = srcImage.captionWords;
        destImage.keywords = srcImage.keywords;
        destImage.orientation = srcImage.orientation;
      }
    },
  },
});
