<script setup lang="ts">
import { useCalendarsStore } from '@/stores/calendars';

const store = useCalendarsStore();
const emits = defineEmits(['sortChanged'])

</script>

<template>
  <div class="btn-group" role="group" aria-label="Basic example">
  <button :class="[store.categorySortProperty == 'code' ? 'btn-secondary' : 'btn-outline-secondary']"
    class="btn btn-sm" @click="$emit('sortChanged', 'code')">
    <i class="bi-sort-alpha-up"></i> AA
  </button>
  <button :class="[store.categorySortProperty == 'name' ? 'btn-secondary' : 'btn-outline-secondary']"
    class="btn btn-sm" @click="$emit('sortChanged', 'name')">
    <i class="bi-sort-alpha-up"></i> Name
  </button>
</div>
</template>

<style lang="scss" scoped></style>
