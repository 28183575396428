import { defineStore } from 'pinia';

export const useTableStore = defineStore('table', {
  state: () => ({
    sortColumn: '',
    sortDescending: false,
    searchText: '',
    searchYear: 0,
    searchShared: false,
    searchVendorId: 0,
    searchStatus: 0
  }),
  actions: {
  },
});
