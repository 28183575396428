<script setup lang="ts">
import { DragCol, DragRow, ResizeCol, ResizeRow, Resize } from 'vue-resizer';

function dragging(e: any) {
  console.log(e);
}
</script>

<template>
  <div class="content">
    <DragRow height="100%" width="100%" :min-height="200">
      <template #top>
        <DragCol height="100%" width="100%" :min-width="200" @dragging="dragging">
          <template #left>
            <div class="panel">
              <h5>left</h5>
              <p>’Twas brillig, and the slithy toves Did gyre and gimble in the wabe: All mimsy were the borogoves, And the mome raths outgrabe.</p>
            </div>
          </template>
          <template #right>
            <DragRow height="100%" width="100%">
              <template #top>
                <div class="panel">
                  <h5>right > top</h5>
                  <p>’Twas brillig, and the slithy toves Did gyre and gimble in the wabe: All mimsy were the borogoves, And the mome raths outgrabe.</p>
                </div>
              </template>
              <template #bottom
                ><div class="panel">
                  <h5>right > bottom</h5>
                  <p>’Twas brillig, and the slithy toves Did gyre and gimble in the wabe: All mimsy were the borogoves, And the mome raths outgrabe.</p>
                </div></template
              >
            </DragRow>
          </template>
        </DragCol>
      </template>
      <template #bottom>
        <DragRow height="100%" width="100%">
          <template #top>
            <div class="panel">
              <h5>bottom > top</h5>
              <p>’Twas brillig, and the slithy toves Did gyre and gimble in the wabe: All mimsy were the borogoves, And the mome raths outgrabe.</p>
            </div>
          </template>

          <template #bottom>
            <div class="panel">
              <h5>bottom > bottom</h5>
              <p>’Twas brillig, and the slithy toves Did gyre and gimble in the wabe: All mimsy were the borogoves, And the mome raths outgrabe.</p>
            </div>
          </template>
        </DragRow>
      </template>
    </DragRow>
  </div>
</template>

<style lang="scss">
.panel {
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  border: 1px solid #bbb;
  background-color: #fff;
  padding: 1rem;
}
</style>
